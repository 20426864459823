<template>
  <div id="app">
    <!--<Home></Home>-->
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #fafafa !important;
  display: flex;
  min-height: inherit;
  align-items: center;
  justify-content: center;
}
::-webkit-scrollbar {
  width: 0px !important; /* Remove scrollbar space */
  background: transparent !important; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: transparent !important;
}
@-moz-document url-prefix() {
  html,
  body {
    overflow: hidden;
  }
}
.swal2-popup {
  max-width: 280px !important;
}
</style>
