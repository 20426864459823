<template>
  <!--<div @click="screenInteracted()">-->
  <div @click="myFunc()">
    <div class="mobile_view" id="xyz" style="justify-content: center">
      <div
        class="container position-relative"
        style="padding: 0px !important; margin: 0; max-width: 100% !important"
      >
        <div
          class="row video_box"
          style="
            position: relative;
            height: 99vh;
            overflow-y: scroll;
            max-height: 100%;
          "
        >
          <div class="col-md-7 p-0">
            <div class="videoDiv" id="videobox">
              <div class="videoSection">
                <video
                  playsinline
                  @click="displayMode = 0"
                  preload="auto"
                  id="video-elem"
                  muted
                  loop
                  autoplay
                >
                  <source :src="videoUrl" />
                  Your browser does not support HTML5 video.
                </video>
                <div class="row">
                  <div>
                    <img
                      width="60px"
                      height="60px"
                      class="brandlogo"
                      :src="brandurl"
                    />
                  </div>
                  <div>
                    <img
                      width="80px"
                      height="70px"
                      class="wtvlogo"
                      src=".././assets/icons/wtv-icon.svg"
                    />
                  </div>
                </div>
                <vodal
                  class="mymodal"
                  :width="310"
                  :height="450"
                  :show="isLoginPopupVisible"
                  animation="rotate"
                >
                  <div
                    style="
                      color: white;
                      background-color: #999;
                      padding: 5px;
                      height: 40px;
                    "
                  >
                    Login
                  </div>
                  <div>
                    <img
                      width="200px"
                      height="200px"
                      style="margin-left: 5%; margin-top: -8%"
                      :src="brandurl"
                    />
                  </div>
                  <div style="display: flex; align-items: center">
                    <div
                      class="container pt-5"
                      style="display: inline-grid; padding-top: 7% !important"
                    >
                      <div v-if="!otpSent">
                        <div class="row" style="justify-content: center">
                          <div class="col-sm-10">
                            <button
                              v-if="!isPhoneInputVisible && !phoneSelected"
                              @click="openPhoneInput"
                              style="
                                width: 100%;
                                margin-top: 5%;
                                margin-bottom: 0%;
                                background-color: #cfd8dc;
                                font-weight: 500;
                              "
                              class="btn"
                            >
                              <i
                                style="
                                  font-size: 24px;
                                  float: left;
                                  color: white;
                                "
                                class="fa fa-phone"
                                aria-hidden="true"
                              ></i
                              >LOGIN WITH PHONE
                            </button>
                            <input
                              class="inputclass form-control"
                              v-model="phoneNumber"
                              placeholder="Enter Phone Number"
                              v-if="isPhoneInputVisible"
                            />
                          </div>
                        </div>
                        <div
                          v-if="phoneSelected"
                          class="row"
                          style="justify-content: center"
                        >
                          <div class="col-sm-10">
                            <button
                              @click="sendOTP"
                              style="
                                width: 100%;
                                margin-top: 5%;
                                background-color: #cfd8dc;
                                font-weight: 500;
                                margin-bottom: 0%;
                              "
                              class="btn"
                            >
                              SEND CODE
                            </button>
                          </div>
                        </div>
                        <div class="row" style="justify-content: center">
                          <div class="col-sm-10">
                            <button
                              v-if="!isEmailInputVisible && !emailSelected"
                              @click="openEmailInput"
                              style="
                                margin-top: 5%;
                                width: 100%;
                                background-color: #cfd8dc;
                                font-weight: 500;
                              "
                              class="btn"
                            >
                              <i
                                style="
                                  font-size: 24px;
                                  float: left;
                                  color: white;
                                "
                                class="fa fa-envelope-o"
                                aria-hidden="true"
                              ></i
                              >LOGIN WITH EMAIL
                            </button>
                            <input
                              class="inputclass form-control"
                              style="margin-top: 9%; margin-bottom: 0%"
                              v-model="email"
                              placeholder="Enter Email"
                              v-if="isEmailInputVisible"
                            />
                          </div>
                        </div>
                        <div
                          v-if="emailSelected"
                          class="row"
                          style="justify-content: center"
                        >
                          <div class="col-sm-10">
                            <button
                              @click="sendOTP"
                              style="
                                width: 100%;
                                margin-top: 5%;
                                background-color: #cfd8dc;
                                font-weight: 500;
                              "
                              class="btn"
                            >
                              SEND CODE
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="otpSent"
                        class="row"
                        style="justify-content: center"
                      >
                        <div class="col-sm-10">
                          <input
                            class="inputclass form-control"
                            v-model="otp"
                            type="password"
                            placeholder="Enter OTP"
                          />
                        </div>
                      </div>
                      <div
                        v-if="otpSent"
                        class="row"
                        style="justify-content: center"
                      >
                        <div class="col-sm-10">
                          <button
                            @click="nextPage()"
                            style="
                              width: 100%;
                              background-color: #cfd8dc;
                              font-weight: 500;
                            "
                            class="btn"
                          >
                            <i
                              style="color: white; font-size: 24px; float: left"
                              class="fa fa-envelope-o"
                              aria-hidden="true"
                            ></i
                            >VERIFY OTP
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </vodal>
              </div>
            </div>
          </div>
          <div class="col-md-5 p-0">
            <div
              id="sidebar"
              style="
                margin-left: -1px;
                position: relative;
                background-color: white;
                padding-bottom: 3px;
              "
            >
              <div
                id="scrolldiv"
                style="position: relative; overflow-y: scroll; max-height: 100%"
              >
                <div>
                  <div class="hh">
                    <div
                      class="mousehvr"
                      style="
                        margin: 2px;
                        padding-right: 0px;
                        padding-left: 0px;
                        margin-right: 0;
                        margin-left: 0;
                        padding-bottom: 15px;
                        cursor: pointer;
                      "
                    >
                      <img
                        :src="product1url"
                        style="height: 30vh; width: 92%; padding: none"
                      />
                    </div>
                  </div>
                  <div class="hh">
                    <div
                      class="mousehvr"
                      style="
                        margin: 2px;
                        padding-right: 0px;
                        padding-left: 0px;
                        margin-right: 0;
                        margin-left: 0;
                        padding-bottom: 15px;
                        cursor: pointer;
                      "
                    >
                      <img
                        :src="product2url"
                        style="height: 30vh; width: 92%; padding: none"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="videotitle">Unboxing WOW !!</div>
          <div>
            <img
              width="100%"
              style="height: 90%"
              class="videotitlelogo"
              src=".././assets/placeholder.png"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
try {
  window.scrollTo(0, 0);
} catch (e) {
  console.log(e);
}

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import Vodal from "vodal";
import "vodal/common.css";
import "vodal/rotate.css";
Vue.component(Vodal.name, Vodal);
Vue.use(VueAxios, axios);

export default {
  name: "Advertisement",
  props: {},
  data() {
    return {
      isVideoMuted: true,
      isLoginPopupVisible: false,
      isPhoneInputVisible: false,
      isEmailInputVisible: false,
      phoneNumber: "",
      email: "",
      phoneSelected: false,
      emailSelected: false,
      otpSent: false,
      myloader: "",
      otp: "",
      mode: "",
    };
  },
  watch: {},
  computed: {
    brandurl: function () {
      var url = window.location.origin + "/assets/logo/logo.svg";
      return url;
    },
    videoUrl: function () {
      //var url = 'https://livetv-wow.s3.ap-south-1.amazonaws.com/assets/DemoVideos/demo1.mp4'
      var url = window.location.origin + "/assets/DemoVideos/demo1.mp4";
      return url;
    },
    product1url: function () {
      //var url = 'https://livetv-wow.s3.ap-south-1.amazonaws.com/assets/products/product1.jpg';
      var url = window.location.origin + "/assets/products/product1.jpg";
      return url;
    },
    product2url: function () {
      //var url = 'https://livetv-wow.s3.ap-south-1.amazonaws.com/assets/products/product1.jpg';
      var url = window.location.origin + "/assets/products/product2.jpg";
      return url;
    },
  },
  mounted() {
    //document.getElementById('sidebar').style.height = (document.getElementById('videobox').clientHeight)/1.0233 + 'px';
    window.scrollTo(0, 0);
    //var vm = this;
    //setTimeout(function(){ vm.$data.isLoginPopupVisible= true }, 7000);
  },
  created() {
    try {
      this.getConfig();
    } catch (e) {
      console.log(e);
    }
    window.addEventListener("resize", this.myEventHandler);
    screen.orientation.lock("portrait-primary");
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  methods: {
    myFunc() {
      window.parent.location.href =
        window.parent.location.origin + "/" + this.$route.params.brand;
      //console.log(window.parent.location.href)
    },
    screenInteracted() {
      this.isLoginPopupVisible = true;
    },
    startLoader() {
      this.myloader = this.$loading.show({
        canCancel: false,
        color: "#34749B",
      });
    },
    stopLoader() {
      this.myloader.hide();
    },
    sendOTP() {
      if (this.isPhoneInputVisible) {
        if (this.phoneNumber == "") {
          alert("Mobile Number is mandatory");
          return;
        } else if (this.phoneNumber.length != 10) {
          alert("Mobile Number is invalid");
          return;
        }
      }
      if (this.isEmailInputVisible) {
        if (this.email == "") {
          alert("Email is mandatory");
          return;
        }
      }
      console.log(sessionStorage.getItem("apikey"));
      const headers = {
        apiKey: sessionStorage.getItem("apikey"),
        deviceId: sessionStorage.getItem("deviceId"),
        userId: sessionStorage.getItem("userId"),
      };
      if (this.isPhoneInputVisible) {
        var phoneNo = "+91" + this.phoneNumber;
        axios
          .post(
            `https://api.wakaw.live/v1/otp`,
            {
              phoneNumber: phoneNo,
              type: "sms",
            },
            { headers: headers }
          )
          .then((response) => {
            console.log(response);
            this.mode = "M";
            this.otpSent = true;
            this.isPhoneInputVisible = false;
            this.isEmailInputVisible = false;
            this.emailSelected = false;
            this.phoneSelected = false;
            console.log("hola");
          })
          .catch((e) => {
            this.errors.push(e);
          });
      } else if (this.isEmailInputVisible) {
        var myemail = this.email;
        axios
          .post(
            `https://api.wakaw.live/v1/otp`,
            {
              email: myemail,
              type: "email",
            },
            { headers: headers }
          )
          .then(() => {
            this.mode = "E";
            this.isPhoneInputVisible = false;
            this.isEmailInputVisible = false;
            this.emailSelected = false;
            this.phoneSelected = false;
          })
          .catch((e) => {
            this.errors.push(e);
          });
      }
    },
    openPhoneInput() {
      this.isPhoneInputVisible = true;
      this.isEmailInputVisible = false;
      this.phoneSelected = true;
      this.emailSelected = false;
    },
    openEmailInput() {
      this.isEmailInputVisible = true;
      this.isPhoneInputVisible = false;
      this.emailSelected = true;
      this.phoneSelected = false;
    },
    nextPage() {
      const headers = {
        apiKey: sessionStorage.getItem("apikey"),
        deviceId: sessionStorage.getItem("deviceId"),
        userId: sessionStorage.getItem("userId"),
      };
      if (this.mode == "M") {
        this.startLoader();
        var phoneNo = "+91" + this.phoneNumber;
        axios
          .post(
            `https://api.wakaw.live/v1/authentication/login`,
            {
              phoneNumber: phoneNo,
              otp: "" + this.otp,
              type: "sms",
            },
            { headers: headers }
          )
          .then((response) => {
            console.log(response);
            console.log(response.data.data.accessToken);
            sessionStorage.setItem("accessToken", response.data.data.accessToken);
            sessionStorage.setItem("userId", response.data.data.userId);
            this.stopLoader();
            if (
              this.$route.params.brand == null ||
              this.$route.params.brand == ""
            ) {
              console.log("sayonaraa");
              this.$router.push({ path: "/404notfound" });
            } else {
              if (
                this.$route.params.videoId != null &&
                this.$route.params.videoId != ""
              ) {
                if (
                  this.$route.query.uid != null &&
                  this.$route.query.uid != ""
                ) {
                  this.$router.push({
                    path:
                      "/Video/" +
                      this.$route.params.brand +
                      "/" +
                      this.$route.params.videoId +
                      "?uid=" +
                      this.$route.query.uid,
                  });
                } else {
                  this.$router.push({
                    path:
                      "/Video/" +
                      this.$route.params.brand +
                      "/" +
                      this.$route.params.videoId,
                  });
                }
              } else {
                this.$router.push({
                  path: "/Video/" + this.$route.params.brand,
                });
              }
            }
          })
          .catch((e) => {
            this.stopLoader();
            console.log("sayonaraa112");
            this.$router.push({ path: "/404notfound" });
            this.errors.push(e);
          });
      } else if (this.mode == "E") {
        this.startLoader();
        var myemail = this.email;
        axios
          .post(
            `https://api.wakaw.live/v1/authentication/login`,
            {
              email: myemail,
              otp: "" + this.otp,
              type: "email",
            },
            { headers: headers }
          )
          .then((response) => {
            console.log(response);
            console.log(response.data.data.accessToken);
            sessionStorage.setItem("accessToken", response.data.data.accessToken);
            sessionStorage.setItem("userId", response.data.data.userId);
            this.stopLoader();
            if (
              this.$route.params.brand == null ||
              this.$route.params.brand == ""
            ) {
              this.$router.push({ path: "/404notfound" });
            } else {
              if (
                this.$route.params.videoId != null &&
                this.$route.params.videoId != ""
              ) {
                if (
                  this.$route.query.uid != null &&
                  this.$route.query.uid != ""
                ) {
                  this.$router.push({
                    path:
                      "/Video/" +
                      this.$route.params.brand +
                      "/" +
                      this.$route.params.videoId +
                      "?uid=" +
                      this.$route.query.uid,
                  });
                } else {
                  this.$router.push({
                    path:
                      "/Video/" +
                      this.$route.params.brand +
                      "/" +
                      this.$route.params.videoId,
                  });
                }
              } else {
                this.$router.push({
                  path: "/Video/" + this.$route.params.brand,
                });
              }
            }
          })
          .catch((e) => {
            this.stopLoader();
            this.$router.push({ path: "/404notfound" });
            this.errors.push(e);
          });
      }
    },
    getConfig() {
      //https.globalAgent.options.rejectUnauthorized = false;
      axios
        .get(
          `https://api.wakaw.live/v1/config?appVersionNumber=v1.0.0&appType=customer&appVersionName=android`
        )
        .then((response) => {
          console.log(response.data.data.apiKey);
          sessionStorage.setItem("apikey", response.data.data.apiKey);
          this.upsertDevice(response.data.data.apiKey);
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    upsertDevice(key) {
      console.log(key);
      const headers = {
        apiKey: key,
      };
      axios
        .post(
          `https://api.wakaw.live/v1/devices`,
          {
            type: "phone",
            osName: "android",
            osVersion: "10.1",
            deviceIdentifier: Math.floor(Math.random() * 10000000000000) + "ert"+ Math.floor(Math.random() * 1000000),
            application: {
              applicationType: "customer",
              applicationName: "WakaW Customer App",
              applicationVersionName: "new version1",
              applicationVersionNumber: "0.2",
              applicationKey: "com.wakaw.customer",
              deviceToken: Math.floor(Math.random() * 10) + "ghsj"+ Math.floor(Math.random() * 10000000),
            },
          },
          { headers: headers }
        )
        .then((response) => {
          console.log(response);
          sessionStorage.setItem("deviceId", response.data.data.deviceId);
          sessionStorage.setItem("userId", response.data.data.userId);
          sessionStorage.setItem("apikey", response.data.data.apiKey);
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    playTheVideo() {
      this.isVideoMuted = false;
      var vid = document.getElementById("video-elem");
      vid.muted = false;
    },
    pauseTheVideo() {
      this.isVideoMuted = true;
      var vid = document.getElementById("video-elem");
      vid.muted = true;
    },
    fullScreenVideo() {
      var elem = document.getElementById("video-elem");
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    },
    playVideo() {},
    getBrandID() {},
    myEventHandler(e) {
      console.log(e);
      document.getElementById("sidebar").style.height =
        document.getElementById("videobox").clientHeight / 1.0233 + "px";
    },
  },
};
</script>

<style scoped>
#app {
  min-height: 100vh !important;
}
.mobile_view.icons {
  position: absolute;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.header {
  position: absolute;
  height: 55px;
  left: 0%;
  right: 0%;
  top: 0px;
  display: inline;
  background: #424242;
  box-sizing: border-box;
}
.feedTitle {
  height: 24px;
  margin: 0;
  margin-left: -150px;
  width: fit-content;
  padding: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: white;
  align-items: center;
}
.vjs_video_578-dimensions {
  width: 500px !important;
  height: 576px !important;
}
::-webkit-scrollbar {
  width: 0px !important; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: transparent !important;
}
.container-fluid {
  padding: 0px;
}
.row {
  margin-left: 0;
  margin-right: 0;
}
.videoSection {
  position: relative;
  overflow: hidden;
}

video {
  width: 100%;
  /*max-height: 700px !important;*/
  height: 98.3vh;
  object-fit: cover !important;
  transform: scale(1.22, 1);
}

@media (max-width: 580px) {
  .shoe_box {
    width: 35%;
  }
}

@media (max-width: 389px) {
  .mobile_view {
    width: 100vw !important;
  }
}
@media (min-height: 500px) and (max-width: 650px) {
  .mobile_view {
    width: 100vw !important;
  }
  video {
    transform: scale(1.39, 1);
  }
}
@media (min-width: 650px) {
  .mobile_view {
    width: 670px !important;
  }
}
@media (min-width: 700px) {
  .mobile_view {
    padding-left: 0px !important;
  }
}
.mobile_view {
  width: 470px;
  height: fit-content;
  /*padding-top:4px;*/
  box-shadow: 0 8px 15px rgba(14, 14, 14, 0.175);
  margin: 0 auto;
  padding-left: 1px;
  padding-right: 1px;
  /*padding-top: 3px;*/
  max-height: 100%;
}
.shoe_box {
  position: relative;
  z-index: 10;
  width: fit-content;
  max-width: 35%;
}
.video_box {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: center;
}
.videoSection img.expand {
  position: absolute;
  z-index: 111;
  /* left: 0px; */
  top: 65px;
  right: 9px;
  color: white;
  margin: 1%;
  font-size: 30px;
  cursor: pointer;
}
div.viewcount {
  position: absolute;
  z-index: 111;
  top: 2.1%;
  right: 36%;
  color: white;
  margin: 1%;
  font-size: 16px;
}
.videoSection i.fa-eye {
  position: absolute;
  z-index: 111;
  top: 2%;
  right: 43%;
  color: white;
  margin: 1%;
  font-size: 25px;
  cursor: pointer;
}
.videoSection img.brandlogo {
  position: absolute;
  z-index: 111;
  top: 0px;
  right: 0%;
  color: white;
  margin: 1%;
  font-size: 30px;
  cursor: pointer;
}
.video_box img.videotitlelogo {
  position: absolute;
  z-index: 111;
  bottom: 0%;
  right: 0%;
  color: white;
  margin: 1%;
  font-size: 30px;
  cursor: pointer;
}
.video_box div.videotitle {
  position: absolute;
  z-index: 112;
  bottom: 3%;
  color: white;
  margin: 1%;
  margin-left: 10%;
  font-size: 3.4rem;
  font-family: fantasy;
  letter-spacing: 3px;
  font-weight: 200;
  cursor: pointer;
}
.videoSection img.wtvlogo {
  position: absolute;
  z-index: 111;
  top: 0px;
  left: 1%;
  color: white;
  margin: 1%;
  font-size: 30px;
  cursor: pointer;
}
.videoSection img.mute {
  position: absolute;
  z-index: 111;
  /* left: 0px; */
  top: 120px;
  right: 8px;
  color: white;
  margin: 1%;
  font-size: 35px;
  cursor: pointer;
}
.videoSection img.speak {
  position: absolute;
  z-index: 111;
  /* left: 0px; */
  top: 120px;
  right: 8px;
  color: white;
  margin: 1%;
  font-size: 35px;
  cursor: pointer;
}
.videoSection img.camera {
  position: absolute;
  z-index: 111;
  /* left: 0px; */
  top: 53%;
  right: 9px;
  color: white;
  margin: 1%;
  font-size: 39px;
  cursor: pointer;
}
.videoSection img.like {
  position: absolute;
  z-index: 111;
  /* left: 0px; */
  top: 71%;
  right: 9px;
  color: white;
  margin: 1%;
  font-size: 38px;
  cursor: pointer;
}
.videoSection img.gift {
  position: absolute;
  z-index: 111;
  /* left: 0px; */
  top: 43%;
  right: 9px;
  color: white;
  margin: 1%;
  font-size: 38px;
  cursor: pointer;
}
img.playv {
  border-radius: 50%;
}
.videoSection img.share {
  position: absolute;
  z-index: 111;
  /* left: 0px; */
  top: 80%;
  right: 9px;
  color: rgb(136, 133, 133);
  margin: 1%;
  font-size: 39px;
  cursor: pointer;
}
.videoSection img.cart {
  position: absolute;
  z-index: 111;
  /* left: 0px; */
  top: 62%;
  right: 9px;
  color: white;
  margin: 1%;
  font-size: 40px;
  cursor: pointer;
}
.videoSection div.userprofile {
  position: absolute;
  z-index: 111;
  /* left: 0px; */
  top: 12%;
  left: 0px;
  color: white;
  cursor: pointer;
  width: 205px;
  background: black;
  opacity: 0.6;
  height: 46px;
  border-top-right-radius: 23px;
  border-bottom-right-radius: 23px;
  display: flex;
  align-items: center;
}
button:active {
  border: none;
}
.videoSection img.userprofileicon {
  border-radius: 50%;
  opacity: 1;
  top: 12%;
  margin-left: 3px;
  margin-right: 3px;
}
.hh img.playv {
  position: absolute;
  z-index: 111;
  /* left: 0px; */
  color: white;
  margin: 33%;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.navbar {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}
.hh {
  margin: 14px;
  border: 0.5px solid #c0c0c0;
  border-radius: 3px;
}
.buy-btn {
  width: 90%;
  background-color: #f43b55 !important;
  border-radius: 4px;
  color: white;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  border: none;
  margin-top: 6px;
}
.buy-btn:active {
  width: 90%;
  background-color: #f43b55 !important;
  border-radius: 4px;
  color: white;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  border: none;
  margin-top: 6px;
}
.vodal,
.vodal-mask {
  z-index: 200 !important;
}
.mymodal >>> .vodal-dialog {
  padding: 0 !important;
}
.center-it {
  display: flex;
  justify-content: center;
  margin-left: -15px;
}
.sm-icons {
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
}
.disabledbutton {
  pointer-events: none;
}
button:hover {
  border: none !important;
}

button:focus {
  border: none !important;
}
.vodal-close {
  display: none !important;
}
.inputclass {
  width: 100%;
  height: 45px;
  font-family: "Comic Neue", cursive !important;
  margin-bottom: 5%;
}
input:focus {
  border-width: 2px;
  border-style: inset;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
}

.pt-5,
.py-5 {
  padding-top: 1vh !important;
}
.input {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;
  letter-spacing: 0.2px;

  /* Primary — 500* */

  color: #838383;
  mix-blend-mode: normal;
}
::-webkit-scrollbar-thumb {
  background: transparent;
}
input:focus {
  border: none;
}
input:active {
  border: none;
}
</style>