<template>
  <div class="container auth-container">
    <div class="row">
      <div
        style="position: relative; max-width: 33% !important; overflow: hidden"
        class="col-4 left-col left-auth"
        id="divid"
      >
        <embed :src="tvurl" style="width: 430px; height: 100%" />
      </div>

      <div class="right-col text-center">
        <div class="header">
          <a class="header__link">
            <div class="logoContainer">
              <!-- <img :src="brandlogourl"/> -->
              <img src="../assets/brandlogo.jpeg" />
            </div>
          </a>
          <div class="textContainer">
            <div class="text">Join us for Shoppable Livestreams</div>
          </div>
          <!-- <p class="header__info">LOGIN</p> -->
        </div>

        <div class="options" v-if="!otpSent">
          <!-- <div class="options__first">
            <button
              v-if="!isPhoneInputVisible && !phoneSelected"
              class="btn btn-primary btn-block auth-btn"
              @click="openPhoneInput"
            >
              Login With Phone
            </button>
          </div>
          <div class="form-label-group" v-if="isPhoneInputVisible">
            <input
              name="phoneNumber"
              id="phoneNumber"
              class="form-control"
              placeholder="Enter Phone Number"
              v-model="phoneNumber"
            />
          </div>
          <div class="options__first" v-if="phoneSelected">
            <button class="btn btn-primary btn-block auth-btn" @click="sendOTP">
              Send Code
            </button>
          </div> -->
          <div style="height: 12px"></div>
          <div class="form-label-group" v-if="isEmailInputVisible">
            <input
              name="email"
              id="email"
              class="form-control"
              placeholder="Enter Email"
              v-model="email"
            />
          </div>
          <div class="options__first" v-if="emailSelected">
            <button class="btn btn-primary btn-block auth-btn" @click="sendOTP()">
              Send Code
            </button>
          </div>
          <div
            class="options__first"
            v-if="!isEmailInputVisible && !emailSelected"
          >
            <button
              class="btn btn-primary btn-block auth-btn"
              @click="openEmailInput"
            >
              Login With Email
            </button>
          </div>
        </div>
        <div class="form-label-group" v-if="otpSent">
          <input
            type="password"
            name="otp"
            id="otp"
            class="form-control"
            placeholder="Enter OTP"
            v-model="otp"
          />
        </div>
        <div class="options__first" v-if="otpSent">
          <button
            class="btn btn-primary btn-block auth-btn"
            @click="nextPage()"
          >
            Verify OTP
          </button>
        </div>
        <div class="otpText">
          <span>One Time Password (OTP) will be sent to your email</span><br/>
          <span>OTP enables access for one session</span>
        </div>
      </div>
      <div
        style="
          position: relative;
          padding-left: 0px !important;
          padding-right: 0px !important;
          overflow: hidden;
        "
        class="col-4 left-col left-auth"
        id="divid"
      >
        <embed :src="tvurl" style="width: 430px; height: 100%" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
Vue.use(Loading);
Vue.use(VueAxios, axios);
import VueSimpleAlert from "vue-simple-alert";
Vue.use(VueSimpleAlert);
export default {
  name: "Home",
  props: {},
  data() {
    return {
      isPhoneInputVisible: false,
      isEmailInputVisible: false,
      phoneNumber: "",
      email: "",
      phoneSelected: false,
      emailSelected: false,
      otpSent: false,
      myloader: "",
      otp: "",
      mode: "",
      init: 0,
    };
  },
  methods: {
    startLoader() {
      this.myloader = this.$loading.show({
        canCancel: false,
        color: "#000000",
      });
    },
    stopLoader() {
      this.myloader.hide();
    },

    showMessage(){
      this.$alert("SSR is back!!","Influencer");
    },

    async sendOTP() {
        if (this.email == "") {
          alert("Email is mandatory");
          return;
        }
      const headers = {
        "apiKey": sessionStorage.getItem("apikey"),
        "deviceId": sessionStorage.getItem("deviceId"),
        "userId": sessionStorage.getItem("userId")
      };
        var myemail = this.email;
        this.startLoader();
        
        await axios
          .post(
            `https://api.wakaw.live/v1/otp`,
            {
              email: myemail,
              type: "email",
            },
            { headers: headers }
          )
          .then(() => {
            this.stopLoader();
            this.otpSent = true;
            this.mode = "E";
            this.isPhoneInputVisible = false;
            this.isEmailInputVisible = false;
            this.emailSelected = false;
            this.phoneSelected = false;
          }).catch(()=>{
            this.stopLoader();
            this.$confirm("Kindly refresh your browser and try logging in");
            console.log("Session", sessionStorage);
          });
    },
    openPhoneInput() {
      this.isPhoneInputVisible = true;
      this.isEmailInputVisible = false;
      this.phoneSelected = true;
      this.emailSelected = false;
    },
    openEmailInput() {
      this.isEmailInputVisible = true;
      this.isPhoneInputVisible = false;
      this.emailSelected = true;
      this.phoneSelected = false;
    },
    async nextPage() {
      const headers = {
        "apiKey": sessionStorage.getItem("apikey"),
        "deviceId": sessionStorage.getItem("deviceId"),
        "userId": sessionStorage.getItem("userId")
      };
      // if (
      //   !(
      //     headers.deviceId &&
      //     headers.deviceId.length > 0 &&
      //     headers.userId &&
      //     headers.userId.length > 0 &&
      //     this.mode
      //   )
      // ) {
      //   this.mode = "";
      //   this.$router.push({
      //     path: "/",
      //   });
      // } 
        this.startLoader();
        var myemail = this.email;
          await axios
          .post(
            `https://api.wakaw.live/v1/authentication/login`,
            {
              "email": myemail,
              "otp": "" + this.otp,
              "type": "email",
            },
            { headers: headers }
          )
          .then((response) => {
            this.stopLoader();
            sessionStorage.setItem("accessToken", response.data.data.accessToken);
            sessionStorage.setItem("userId", response.data.data.userId);
            sessionStorage.setItem("userName", response.data.data.username);
              if (this.$route.params.videoId != null && this.$route.params.videoId != "") {
                if ( this.$route.query.uid != null && this.$route.query.uid != "") {
                  this.$router.push({
                    path:
                      "/Video/" +
                      this.$route.params.videoId +
                      "?uid=" +
                      this.$route.query.uid
                  });
                } else {
                    this.stopLoader();
                    this.$router.push({
                      path: "/Video/" + this.$route.params.videoId,
                    });
                  }
              } else {
              this.stopLoader();
              this.$router.push({ path: "/Video" });
              
            }
          }).catch(()=> {
            this.stopLoader();
            this.$confirm("Kindly refresh your browser and try logging in");
            console.log("Session", sessionStorage);
          })
    },

    async getConfig() {
      await axios
        .get(
          `https://api.wakaw.live/v1/config?appVersionNumber=v1.0.0&appType=customer&appVersionName=android`
        )
        .then((response) => {
          sessionStorage.setItem("apikey", response.data.data.apiKey);
          this.upsertDevice(response.data.data.apiKey);
        })
        .catch(() => {
          this.$confirm("Something Went Wrong", "Error", "error")
        });
    },
    async upsertDevice(key) {
      const headers = {
        apiKey: key,
      };
      await axios
        .post(
          `https://api.wakaw.live/v1/devices`,
          {
            type: "phone",
            osName: "android",
            osVersion: "10.1",
            deviceIdentifier: Math.floor(Math.random() * 10000000000000) + "ert"+ Math.floor(Math.random() * 1000000),
            application: {
              applicationType: "customer",
              applicationName: "WakaW Customer App",
              applicationVersionName: "new version1",
              applicationVersionNumber: "0.2",
              applicationKey: "com.wakaw.customer",
              deviceToken: Math.floor(Math.random() * 10) + "ghsj"+ Math.floor(Math.random() * 10000000),
            },
          },
          { headers: headers }
        )
        .then((response) => {
          sessionStorage.setItem("deviceId", response.data.data.deviceId);
          sessionStorage.setItem("userId", response.data.data.userId);
          sessionStorage.setItem("apikey", response.data.data.apiKey);
        })
        .catch(() => {
          this.$confirm("Something Went Wrong", "Error", "error");
        });
    },

  },
  computed: {
    brandlogourl: function () {
      var url = window.location.origin + "/assets/logo/logo.svg";
      return url;
    },
    tvurl: function () {
      var url = window.location.origin + "/TV";
      return url;
    },
  },
  mounted() {
    try {
      if (sessionStorage.getItem("accessToken")) {
        this.$router.push({ path: "/Video" });
      } else {
        try {
          this.getConfig();
        } catch (e) {
          console.log(e);
        }
      }
    } catch (e) {
      console.log(e);
    }
    window.addEventListener("resize", this.myEventHandler);
    screen.orientation.lock("portrait-primary");
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
};
</script>

<style scoped>
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col-4 {
  flex: 0 0 30%;
  max-width: 30%;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 0px !important;
  padding-left: 15px;
}

.auth-container .right-col {
  background-color: #cd3533;
  border: 1px solid #dbdbdb;
  width: 400px;
  float: right;
  padding-top: 20px;
  height: 98vh;
}
.logoContainer{
  display: flex;
  flex-direction: column;
  padding: 0 40px;
  justify-content: center;
}
.textContainer{
    font-size: 20px;
    padding-top: 20px;
    padding-bottom: 50px;
    color: white;
    font-weight: bold;
}
.text-center {
  text-align: center !important;
}
a {
  color: black;
  text-decoration: none;
  background-color: transparent;
}
.header__info {
  font-size: 20px;
  line-height: 30px;
  color: white;
}
.otpText{
  font-size: 14px;
  color: white;
  padding: 20px 30px;
}
@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}

@media (max-width: 992px) {
  .auth-container .right-col {
    margin: 20px auto;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

@media (max-width: 400px) {
  .auth-container .right-col {
    width: 100%;
  }
}

.form-control {
  background: #fafafa;
  padding: 8px 0 8px 10px;
  font-size: 14px;
  line-height: 18px;
  min-height: 40px;
  margin-bottom: 1rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-weight: 400;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
}
@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 1024px;
  }
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 100%;
  }
}
@media (max-width: 800px) {
  .left-auth {
    display: none;
  }
  .container {
    padding-right: 0px !important;
  }
}

.container {
  width: 100%;
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin-right: 15px !important;
  margin-left: 15px !important;
}
.btn-primary {
  background-color: white !important;
  border-color: white !important;
  color: grey !important;
}
.btn-primary:hover {
  background-color: white !important;
  border-color: white !important;
}
.btn-primary:active {
  background-color: white !important;
  border-color: white !important;
}
.btn-primary:focus {
  background-color: white !important;
  border-color: white !important;
}
.left-col {
  flex: 10 10 30%;
}
.circle span {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90px;
  color: #ffffff;
  font-weight: bold;
  font-size: 20px;
}
.circle {
  background: #0b0b0b;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  margin: 5px;
  position: relative;
}
.wtv-logo-container {
  height: 90px;
  margin-top: 70%;
}
.form-label-group{
    padding: 0 40px;
    margin-top: 15px;
    position: relative;
}
.options__first{
  padding: 0 40px;
  position: relative;
}
#videoTag{
  width: 100%;
}
.backgroundVideo{
    position: absolute;
    height: 100%;
    overflow-y: hidden;
}


@-moz-document url-prefix() {
  .auth-container .right-col {
    background-color: #c00 !important;
  } 
  }
</style>
