<template>
  <div class="layout-wrapper">
    <div
      :class="{
        isWebView: !isMobile,
        isMobileView: isMobile,
        'chat-wrapper': true,
      }"
      v-show="isOpenChat"
    >
      <div class="chat-header">
        <span>Chat</span>
        <span class="chat-close" @click="isOpenChat = false">Close</span>
      </div>
      <div class="chat-content-wrapper">
        <div
          class="chat-block"
          v-for="(chat, index) in chatObject"
          :key="index"
        >
          <div class="chat-avatar" v-show="chat.image">
            <img :src="chat.image" alt="" />
          </div>
          <div class="chat-message-block">
            <div class="chat-name">{{ chat.name }}</div>
            <div class="chat-message">
              {{ chat.message }}
            </div>
          </div>
        </div>
      </div>
      <div class="chat-footer">
        <textarea
          class="chat-message-input"
          placeholder="Say something"
          id="channelMessage"
        />
        <button
          type="button"
          class="chat-send-button"
          id="send_channel_message"
        >
          Send
        </button>
      </div>
    </div>
    <div class="mobile_view" style="justify-content: center">
      <div
        class="container position-relative"
        style="padding: 0px !important; margin: 0; max-width: 100% !important"
      >
        <button
          type="button"
          class="redirect"
          @click="redirectToWebsite()"
          v-if="this.$route.params.videoId"
        >
          Go Website
        </button>
        <div class="row shoe_box">
          <div
            id="sidebar"
            style="
              margin-left: -1px;
              position: relative;
              background-color: white;
              padding-bottom: 3px;
            "
          >
            <div
              :class="{ activeS: displayMode == 3 }"
              v-if="displayMode != 0 && displayMode != 2"
              @click="getLiveStreamingEvents()"
              style="
                height: 26px;
                cursor: pointer;
                padding-left: 4px;
                padding-right: 2px;
                position: absolute;
                right: -53px;
                top: 35px;
                background-color: white;
                transform: rotate(90deg);
                z-index: -1;
                color: #c0c0c0;
                border-radius: 2px;
              "
            >
              Live Events
            </div>
            <div
              @click="
                displayMode = 1;
                currVideotype = '';
                currPage = 1;
                getVideoFeedForBrand(currPage, brandId, 0);
              "
              :class="{ activeS: displayMode == 1 }"
              v-if="displayMode != 0 && displayMode != 2"
              style="
                cursor: pointer;
                padding-left: 3px;
                padding-right: 3px;
                position: absolute;
                right: -54px;
                top: 125px;
                background-color: white;
                transform: rotate(90deg);
                z-index: -1;
                color: #c0c0c0;
                border-radius: 2px;
              "
            >
              Video Feed
            </div>
            <div
              @click="
                currPage = 1;
                currVideoType = 'Teaser';
                getUpcomingEvents();
              "
              :class="{ activeS: displayMode == 4 }"
              v-if="displayMode != 0 && displayMode != 2"
              style="
                cursor: pointer;
                padding-left: 3px;
                padding-right: 3px;
                position: absolute;
                right: -51px;
                top: 215px;
                background-color: white;
                transform: rotate(90deg);
                z-index: -1;
                color: #c0c0c0;
                border-radius: 2px;
              "
            >
              Upcoming
            </div>
            <div
              id="scrolldiv"
              style="
                position: relative;
                height: 100vh;
                overflow-y: scroll;
                max-height: 100%;
                box-shadow: rgb(14 14 14 / 18%) 2px 2px 2px;
              "
              v-if="displayMode == 1 || displayMode == 4"
            >
              <div>
                <div
                  @click="changePage(currPage, -1)"
                  v-if="currPage != 1"
                  style="
                    cursor: pointer;
                    padding-top: 3px;
                    padding-bottom: 3px;
                    background-color: black;
                    color: white;
                  "
                  class="hh"
                >
                  &lt;&lt; Page {{ currPage - 1 }}
                </div>
                <div
                  class="hh"
                  v-for="(i, index) in videoDetailsList"
                  :key="index"
                >
                  <img
                    @click="playVideo(index, i)"
                    class="playv"
                    width="35px"
                    height="35px"
                    src=".././assets/play-icon2.png"
                  />
                  <div
                    v-if="i.thumbnailUrls"
                    class="mousehvr"
                    @click="playVideo(index, i)"
                    style="
                      margin: 2px;
                      padding-right: 0px;
                      padding-left: 0px;
                      margin-right: 0;
                      margin-left: 0;
                      padding-bottom: 0px;
                    "
                  >
                    <img
                      width="115px"
                      v-if="i.thumbnailUrls"
                      :src="i.thumbnailUrls"
                      style="
                        height: 130px;
                        width: 100%;
                        object-fit: cover;
                        padding: none;
                        border-radius: 15px;
                      "
                    />
                    <div
                      class="t-class"
                      style="font-size: 12px; font-family: sans-serif"
                    >
                      {{ i.title }}
                    </div>
                  </div>
                  <div
                    v-else
                    class="mousehvr"
                    @click="playVideo(index, i)"
                    style="
                      margin: 2px;
                      padding-right: 0px;
                      padding-left: 0px;
                      margin-right: 0;
                      margin-left: 0;
                      padding-bottom: 0px;
                    "
                  >
                    <img
                      width="115px"
                      v-if="!i.thumbnailUrls"
                      src=".././assets/videothumbnail.jpg"
                      style="
                        height: 130px;
                        width: 100%;
                        object-fit: cover;
                        padding: none;
                        border-radius: 15px;
                      "
                    />
                    <div
                      class="t-class"
                      style="font-size: 12px; font-family: sans-serif"
                    >
                      {{ i.title }}
                    </div>
                  </div>
                </div>
                <div
                  @click="changePage(currPage, 1)"
                  style="
                    cursor: pointer;
                    padding-top: 3px;
                    padding-bottom: 3px;
                    background-color: black;
                    color: white;
                  "
                  class="hh"
                >
                  Page {{ currPage + 1 }} >>
                </div>
              </div>
              <div
                v-if="displayMode == 3"
                style="min-width: 100px; background: white"
              >
                <div
                  class="hh"
                  v-for="(i, index) in liveVideoList"
                  :key="index"
                >
                  <img
                    @click="playLiveVideo(index, i)"
                    class="playv"
                    width="35px"
                    height="35px"
                    src=".././assets/play-icon2.png"
                  />
                  <div
                    class="mousehvr"
                    @click="playLiveVideo(index, i)"
                    style="
                      margin: 2px;
                      padding-right: 0px;
                      padding-left: 0px;
                      margin-right: 0;
                      margin-left: 0;
                      padding-bottom: 0px;
                    "
                  >
                    <img
                      v-if="i.thumbnail"
                      width="115px"
                      :src="i.thumbnail"
                      style="
                        height: 130px;
                        width: 100%;
                        object-fit: cover;
                        padding: none;
                        border-radius: 15px;
                      "
                    />
                    <img
                      v-else
                      width="115px"
                      src=".././assets/videothumbnail.jpg"
                      style="
                        height: 130px;
                        width: 100%;
                        object-fit: cover;
                        padding: none;
                        border-radius: 15px;
                      "
                    />
                    <div
                      class="t-class"
                      style="font-size: 12px; font-family: sans-serif"
                    >
                      {{ i.title }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="displayMode == 2">
                <div
                  class="hh"
                  v-for="(i, index) in productDetailsList"
                  :key="index"
                >
                  <div
                    v-if="i.images[0]"
                    class="mousehvr"
                    @click="showProduct(i)"
                    style="
                      margin: 2px;
                      padding-right: 0px;
                      padding-left: 0px;
                      margin-right: 0;
                      margin-left: 0;
                      padding-bottom: 5px;
                      cursor: pointer;
                    "
                  >
                    <img
                      v-if="i.images[0]"
                      :src="i.images[0]"
                      style="
                        height: 130px;
                        width: 100%;
                        min-width: 120px;
                        padding: none;
                        border-radius: 15px;
                      "
                    />
                    <div
                      class="t-class"
                      style="
                        font-size: 12px;
                        font-family: sans-serif;
                        margin-bottom: 3px;
                      "
                    >
                      {{ i.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Search ScrollDiv -->
            <div
              id="scrolldiv"
              style="
                position: relative;
                height: auto;
                overflow-y: scroll;
                max-height: 100%;
                box-shadow: rgb(14 14 14 / 18%) 2px 2px 2px;
              "
            >
              <div v-if="isSearch">
                <div
                  @click="changePage(currPage, -1)"
                  v-if="currPage != 1"
                  style="
                    cursor: pointer;
                    padding-top: 3px;
                    padding-bottom: 3px;
                    background-color: black;
                    color: white;
                  "
                  class="hh"
                >
                  &lt;&lt; Page {{ currPage - 1 }}
                </div>
                <div class="hh" v-for="(i, index) in arr" :key="index">
                  <img
                    @click="playVideo(index, i)"
                    class="playv"
                    width="35px"
                    height="35px"
                    src=".././assets/play-icon2.png"
                  />
                  <div
                    v-if="i.thumbnailUrls"
                    class="mousehvr"
                    @click="playVideo(index, i)"
                    style="
                      margin: 2px;
                      padding-right: 0px;
                      padding-left: 0px;
                      margin-right: 0;
                      margin-left: 0;
                      padding-bottom: 0px;
                    "
                  >
                    <img
                      width="115px"
                      v-if="i.thumbnailUrls"
                      :src="i.thumbnailUrls"
                      style="
                        height: 130px;
                        width: 100%;
                        object-fit: cover;
                        padding: none;
                        border-radius: 15px;
                      "
                    />
                    <div
                      class="t-class"
                      style="font-size: 12px; font-family: sans-serif"
                    >
                      {{ i.title }}
                    </div>
                  </div>
                  <div
                    v-else
                    class="mousehvr"
                    @click="playVideo(index, i)"
                    style="
                      margin: 2px;
                      padding-right: 0px;
                      padding-left: 0px;
                      margin-right: 0;
                      margin-left: 0;
                      padding-bottom: 0px;
                    "
                  >
                    <img
                      width="115px"
                      v-if="!i.thumbnailUrls"
                      src=".././assets/videothumbnail.jpg"
                      style="
                        height: 130px;
                        width: 100%;
                        object-fit: cover;
                        padding: none;
                        border-radius: 15px;
                      "
                    />
                    <div
                      class="t-class"
                      style="font-size: 12px; font-family: sans-serif"
                    >
                      {{ i.title }}
                    </div>
                  </div>
                </div>
                <div
                  @click="changePage(currPage, 1)"
                  style="
                    cursor: pointer;
                    padding-top: 3px;
                    padding-bottom: 3px;
                    background-color: black;
                    color: white;
                  "
                  class="hh"
                >
                  Page {{ currPage + 1 }} >>
                </div>
              </div>
              <div
                v-if="displayMode == 3"
                style="min-width: 100px; background: white"
              >
                <div
                  class="hh"
                  v-for="(i, index) in liveVideoList"
                  :key="index"
                >
                  <img
                    @click="playLiveVideo(index, i); fetchData()"
                    class="playv"
                    width="35px"
                    height="35px"
                    src=".././assets/play-icon2.png"
                  />
                  <div
                    class="mousehvr"
                    @click="playLiveVideo(index, i); fetchData()"
                    style="
                      margin: 2px;
                      padding-right: 0px;
                      padding-left: 0px;
                      margin-right: 0;
                      margin-left: 0;
                      padding-bottom: 0px;
                    "
                  >
                    <img
                      v-if="i.thumbnail"
                      width="115px"
                      :src="i.thumbnail"
                      style="
                        height: 130px;
                        width: 100%;
                        object-fit: cover;
                        padding: none;
                        border-radius: 15px;
                      "
                    />
                    <img
                      v-else
                      width="115px"
                      src=".././assets/videothumbnail.jpg"
                      style="
                        height: 130px;
                        width: 100%;
                        object-fit: cover;
                        padding: none;
                        border-radius: 15px;
                      "
                    />
                    <div
                      class="t-class"
                      style="font-size: 12px; font-family: sans-serif"
                    >
                      {{ i.title }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="displayMode == 2">
                <div
                  class="hh"
                  v-for="(i, index) in productDetailsList"
                  :key="index"
                >
                  <div
                    v-if="i.images[0]"
                    class="mousehvr"
                    @click="showProduct(i)"
                    style="
                      margin: 2px;
                      padding-right: 0px;
                      padding-left: 0px;
                      margin-right: 0;
                      margin-left: 0;
                      padding-bottom: 5px;
                      cursor: pointer;
                    "
                  >
                    <img
                      v-if="i.images[0]"
                      :src="i.images[0]"
                      style="
                        height: 130px;
                        width: 100%;
                        min-width: 120px;
                        padding: none;
                        border-radius: 15px;
                      "
                    />
                    <div
                      class="t-class"
                      style="
                        font-size: 12px;
                        font-family: sans-serif;
                        margin-bottom: 3px;
                      "
                    >
                      {{ i.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button
              v-if="displayMode == 2"
              @click="openGiftPromo()"
              class="buy-btn"
            >
              BUY
            </button>
          </div>
        </div>
        <div class="row video_box">
          <div class="col-md-12 p-0">
            <div class="videoDiv" id="videobox">
              <div
                v-show="!isVideoCallActive && !isLiveStreamActive"
                class="videoSection"
                id="inbox"
              >
                <video
                  playsinline
                  @click="
                    displayMode = 0;
                    isSearch = false;
                  "
                  preload="auto"
                  id="video-elem"
                  muted
                  loop
                  autoplay
                >
                  <source
                    :src="currentVideoObject.videoUrls.MP4[0]"
                    v-if="currentVideoObject.videoUrls"
                  />
                  Your browser does not support HTML5 video.
                </video>
                <div
                  v-show="
                    currentVideoObject.videoUrls &&
                      displayMode == 0 &&
                      !isSearch
                  "
                  class="footer-class row"
                >
                  <div
                    v-if="isVideoMuted"
                    @click="playTheVideo()"
                    style="
                      cursor: pointer;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      margin-right: 20px;
                    "
                  >
                    <img
                      width="25px"
                      height="25px"
                      src=".././assets/muted.png"
                    />
                  </div>
                  <div
                    v-if="!isVideoMuted"
                    @click="pauseTheVideo()"
                    style="
                      cursor: pointer;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      margin-right: 20px;
                    "
                  >
                    <img
                      width="20px"
                      height="20px"
                      src=".././assets/vol1.svg"
                    />
                  </div>
                  <div
                    @click="likeCurrVideo()"
                    v-if="!isVideoLiked"
                    style="
                      cursor: pointer;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <img
                      width="20px"
                      height="20px"
                      src=".././assets/heart1.svg"
                    />
                  </div>
                  <div
                    v-if="isVideoLiked"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <img
                      width="20px"
                      height="20px"
                      src=".././assets/heart2.svg"
                    />
                  </div>
                  <div class="col-6">
                    <button @click="startVideoCall()" class="fbtn" disabled>
                      Chat with an Expert
                    </button>
                  </div>
                  <div
                    @click="
                      isSharePopupVisible = true;
                      displayMode = 0;
                    "
                    style="
                      cursor: pointer;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      margin-right: 16px;
                    "
                  >
                    <img
                      width="20px"
                      height="20px"
                      src=".././assets/shareimg.svg"
                    />
                  </div>
                  <div
                    @click="isFollowPopupOpened = !isFollowPopupOpened"
                    style="
                      cursor: pointer;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <img
                      width="20px"
                      height="20px"
                      src=".././assets/follow.png"
                    />
                  </div>
                  <div class="searchContainer" @click="isShowProduct = true">
                    <img
                      class="search"
                      width="35px"
                      height="35px"
                      src=".././assets/search.svg"
                      style="
                        padding: 6px;
                        margin-left: 6px;
                      "
                    />
                  </div>
                </div>
                <div class="row">
                  <div>
                    <i
                      class="fa fa-eye"
                      width="100px"
                      height="80px"
                      aria-hidden="true"
                    ></i>
                    <div class="viewcount">{{ viewsCurrVideo }}</div>
                  </div>
                  <div class="brand-logo">
                    <img
                      width="50px"
                      height="50px"
                      class="brandlogo"
                      src="../assets/logo.svg"
                    />
                  </div>

                  <!-- 
                  <div class="wtv-logo-container">
                    <div class="circle"><span>W TV</span></div>
                  </div> -->

                  <!--<div>
                    <img
                      width="80px"
                      height="70px"
                      class="wtvlogo"
                      src=".././assets/icons/wtv-icon.svg"
                    />
                  </div>-->
                </div>
                <!-- <vodal
                  class="mymodal"
                  :width="220"
                  :height="400"
                  :show="showProductObj"
                  animation="rotate"
                  @hide="
                    showProductObj = false;
                    product = {};
                  "
                >
                  <div
                    style="color: white; background-color: #999; padding: 5px"
                  >
                    {{ brandname }}
                  </div>
                  <div
                    style="padding: 10px; max-height: 350px; overflow-y: scroll"
                  >
                    <img width="170" height="170" :src="product.url" />
                    <br /><br /><br />
                    <span style="font-size: 15px">{{ product.title }}</span>
                    <br /><br />
                    <span style="font-size: 17px; font-weight: bold"
                      >$ {{ product.price }}</span
                    >
                  </div>
                </vodal> -->
                <vodal
                  class="mymodal"
                  :width="200"
                  :height="215"
                  :show="isSharePopupVisible"
                  animation="rotate"
                  @hide="isSharePopupVisible = false"
                >
                  <div
                    style="color: white; background-color: #999; padding: 5px"
                  >
                    Share On :
                  </div>
                  <br />
                  <div class="row center-it">
                    <div class="col-2 sm-icons">
                      <facebook
                        :url="pageurl"
                        :title="currentVideoObject.title"
                        scale="3"
                      ></facebook>
                    </div>
                    <div class="col-1 sm-icons"></div>
                    <div class="col-2 sm-icons">
                      <twitter
                        :url="pageurl"
                        :title="currentVideoObject.title"
                        scale="3"
                      ></twitter>
                    </div>
                  </div>
                  <br />
                  <div class="row center-it">
                    <div class="col-2 sm-icons">
                      <telegram
                        :url="pageurl"
                        :title="currentVideoObject.title"
                        scale="3"
                      ></telegram>
                    </div>
                    <div class="col-1 sm-icons"></div>
                    <div class="col-2 sm-icons">
                      <whats-app
                        :url="pageurl"
                        :title="currentVideoObject.title"
                        scale="3"
                      ></whats-app>
                    </div>
                  </div>
                </vodal>
                <div v-show="currentVideoObject.videoUrls">
                  <!--<div @click="startVideoCall()">
                    <img
                      width="40px"
                      height="40px"
                      class="vcall"
                      src=".././assets/icons/vuser.png"
                    />
                  </div>-->

                  <!-- <div v-if="!isGiftIconClicked" @click="openGiftPromo()">
                    <img
                      width="35px"
                      height="35px"
                      class="gift"
                      style="
                        background-color: white;
                        padding: 4px;
                        border-radius: 30%;
                      "
                      src=".././assets/gift.svg"
                    />
                  </div>
                  <div v-if="isGiftIconClicked" @click="openGiftPromo()">
                    <img
                      width="35px"
                      height="35px"
                      class="gift"
                      style="
                        background-color: white;
                        padding: 4px;
                        border-radius: 30%;
                      "
                      src=".././assets/gift.svg"
                    />
                  </div> -->

                  <!--<div v-if="isVideoMuted" @click="playTheVideo()">
                    <img
                      width="35px"
                      height="35px"
                      class="speak"
                      src=".././assets/icons/volume_off.svg"
                    />
                  </div>
                  <div v-if="!isVideoMuted" @click="pauseTheVideo()">
                    <img
                      width="35px"
                      height="35px"
                      class="mute"
                      src=".././assets/icons/volume_on.svg"
                    />
                  </div>-->
                  <div @click="displayMode = 1" v-if="displayMode != 1">
                    <img
                      class="camera"
                      width="35px"
                      height="35px"
                      src=".././assets/video.svg"
                      style="
                        background-color: white;
                        padding: 4px;
                        border-radius: 30%;
                      "
                    />
                  </div>
                  <div @click="displayMode = 1" v-if="displayMode == 1">
                    <img
                      class="camera"
                      width="35px"
                      height="35px"
                      src=".././assets/video.svg"
                      style="
                        background-color: white;
                        padding: 4px;
                        border-radius: 30%;
                      "
                    />
                  </div>
                  <div class="videoIcon">
                    <span class="searchTitle">Video</span>
                  </div>

                  <div @click="fetchProductDetails" v-if="displayMode != 2">
                    <img
                      class="cart"
                      width="35px"
                      height="35px"
                      src=".././assets/trolley.svg"
                      style="
                        background-color: white;
                        padding: 4px;
                        border-radius: 30%;
                      "
                    />
                  </div>

                  <div @click="fetchProductDetails" v-if="displayMode == 2">
                    <img
                      class="cart"
                      width="35px"
                      height="35px"
                      src=".././assets/trolley.svg"
                      style="
                        background-color: white;
                        padding: 4px;
                        border-radius: 30%;
                      "
                    />
                  </div>
                  <div class="shop searchTitle">
                    <span>Shop</span>
                  </div>

                  <vodal
                    class="mymodal"
                    :width="330"
                    :height="200"
                    :show="isShowProduct"
                    animation="rotate"
                    @hide="
                      isShowProduct = false;
                      product = {};
                    "
                  >
                    <div class="inputSearchContainer">
                      <input
                        type="search"
                        placeholder="Search Products...."
                        v-model="searchProduct"
                      />
                      <button
                        type="submit"
                        class="submitBtn"
                        @click="getSearchEvents()"
                      >
                        Submit
                      </button>
                    </div>
                  </vodal>
                  <div
                    v-if="displayMode == 0 && isFollowPopupOpened"
                    @click="followUser()"
                    class="userprofile"
                  >
                    <img
                      class="userprofileicon"
                      width="31px"
                      height="31px"
                      src=".././assets/profile.png"
                    />{{ videoUserName
                    }}<button
                      style="
                        width: 85px;
                        height: 30px;
                        background-color: white;
                        color: black;
                        font-size: 16px;
                        border-radius: 15px;
                        margin-left: 10px;
                        border: none;
                      "
                    >
                      {{ followStatus }}
                    </button>
                  </div>
                </div>
              </div>

              <vodal
                class="mymodal"
                :width="220"
                :height="400"
                :show="
                  showProductObj &&
                    (isMobile ||
                      (!isMobile &&
                        Object.keys(currLivestreamObj).length === 0))
                "
                animation="rotate"
                @hide="
                  showProductObj = false;
                  product = {};
                "
              >
                <div style="color: white; background-color: #999; padding: 5px">
                  {{ brandname }}
                </div>
                <div
                  style="padding: 10px; max-height: 350px; overflow-y: scroll"
                >
                  <img width="170" height="170" :src="product.url" />
                  <span
                    style="font-size: 15px; margin: 8px 0; display: block;"
                    >{{ product.title }}</span
                  >
                  <span
                    style="font-size: 17px; display: block; font-weight: bold"
                    >$ {{ product.price }}</span
                  >
                  <a
                    v-if="product.redirectUrl"
                    :href="product.redirectUrl"
                    class="buy-now-redirect"
                    target="_blank"
                    >Buy Now</a
                  >
                </div>
              </vodal>

              <div v-show="currentVideoObject.videoUrls && isVideoCallActive">
                <div class="videoSection" style="position: static !important">
                  <div v-if="!isGiftIconClicked" @click="openGiftPromo()">
                    <img
                      width="35px"
                      height="35px"
                      class="gift"
                      src=".././assets/gift.svg"
                    />
                  </div>
                  <div v-if="isGiftIconClicked" @click="openGiftPromo()">
                    <img
                      width="35px"
                      height="35px"
                      class="gift"
                      src=".././assets/gift.svg"
                    />
                  </div>

                  <div v-if="queueNo" class="queueSection">
                    <span class="queue">#{{ queueNo }}</span
                    >Your call has been placed in queue, Please refresh and try
                    again.
                  </div>
                  <div @click="fetchProductDetails" v-if="displayMode != 2">
                    <img
                      class="cart"
                      width="35px"
                      height="35px"
                      src=".././assets/icons/6.svg"
                    />
                  </div>
                  <div @click="fetchProductDetails" v-if="displayMode == 2">
                    <img
                      class="cart"
                      width="35px"
                      height="35px"
                      src=".././assets/icons/5.svg"
                    />
                  </div>
                  <div class="screenShareContainer" id="screenShare">
                    <img
                      class="screenShare"
                      width="35px"
                      height="35px"
                      src=".././assets/share-screen.svg"
                      style="
                        border-radius: 30%;
                        "
                    />
                  </div>
                </div>
              </div>
              <div
                v-show="isVideoCallActive"
                id="callDiv"
                class="callsection"
                style="width: 100%; background-color: black"
              >
                <div>
                  <img
                    v-if="queueNo"
                    @click="disconnectCallQueue"
                    class="calld"
                    width="45px"
                    height="45px"
                    src=".././assets/icons/calld.png"
                  />
                </div>
                <div>
                  <img
                    v-if="!queueNo"
                    @click="disconnectCall"
                    class="calld"
                    width="45px"
                    height="45px"
                    src=".././assets/icons/calld.png"
                  />
                </div>
                <div v-if="isAudioEnabledForVideoCall">
                  <img
                    @click="muteCall"
                    class="vcallmute"
                    width="45px"
                    height="45px"
                    src=".././assets/icons/vmute.png"
                  />
                </div>
                <div v-if="!isAudioEnabledForVideoCall">
                  <img
                    @click="unmuteCall"
                    class="vcallmute"
                    width="45px"
                    height="45px"
                    src=".././assets/icons/vunmute.png"
                  />
                </div>
                <div v-if="isVideoEnabled">
                  <img
                    @click="disableVideo"
                    class="vuser"
                    width="45px"
                    height="45px"
                    src=".././assets/icons/vuser.png"
                  />
                </div>
                <div v-if="!isVideoEnabled">
                  <img
                    @click="enableVideo"
                    class="vuser"
                    width="45px"
                    height="45px"
                    src=".././assets/icons/vuser1.png"
                  />
                </div>
                <div
                  v-show="isVideoEnabled"
                  id="me"
                  style="
                    width: 110px;
                    height: 160px;
                    right: 0px;
                    position: absolute;
                    z-index: 900;
                  "
                ></div>
                <div
                  id="remote-container"
                  style="width: 100%; height: 0%"
                ></div>
                <div
                  id="canvas-container"
                  style="width: 100%; height: 100%"
                ></div>
              </div>
              <div
                v-show="isLiveStreamActive"
                id="liveStreamDiv"
                class="callsection streamdiv"
                style="width: 100%; background-color: black"
              >
                <div>
                  <img
                    @click="disconnectStream"
                    class="calld1"
                    width="31px"
                    height="31px"
                    src=".././assets/icons/cross.webp"
                  />
                </div>
                <div @click="displayMode = 1" v-if="displayMode != 1">
                  <img
                    class="camera"
                    width="35px"
                    height="35px"
                    src=".././assets/video.svg"
                    style="
                        background-color: white;
                        padding: 4px;
                        border-radius: 30%;
                      "
                  />
                </div>
                <div @click="displayMode = 1" v-if="displayMode == 1">
                  <img
                    class="camera"
                    width="35px"
                    height="35px"
                    src=".././assets/video.svg"
                    style="
                      background-color: white;
                      padding: 4px;
                      border-radius: 30%;
                    "
                  />
                </div>

                <!-- Cart Button -->
                <!-- <div @click="fetchProductDetailsLive" v-if="displayMode != 2">
                  <img
                    class="cart"
                    width="35px"
                    height="35px"
                    src=".././assets/trolley.svg"
                    style="
                      background-color: white;
                      padding: 4px;
                      border-radius: 30%;
                    "
                  />
                </div>
                <div @click="fetchProductDetailsLive" v-if="displayMode == 2">
                  <img
                    class="cart"
                    width="35px"
                    height="35px"
                    src=".././assets/trolley.svg"
                    style="
                        background-color: white;
                        padding: 4px;
                        border-radius: 30%;
                      "
                  />
                </div> -->
                <!-- <div v-if="!isGiftIconClicked" @click="openGiftPromo()">
                  <img
                    width="35px"
                    height="35px"
                    class="giftlive"
                    src=".././assets/gift.svg"
                    style="background-color: white; padding: 4px; border-radius: 30%;"
                  />
                </div>
                <div v-if="isGiftIconClicked" @click="openGiftPromo()">
                  <img
                    width="35px"
                    height="35px"
                    class="giftlive"
                    src=".././assets/gift.svg"
                    style="background-color: white; padding: 4px; border-radius: 30%;"
                  />
                </div> -->
                <div
                  id="remote-container-live"
                  style="width: 100%; height: 0%"
                ></div>
                <div
                  id="canvas-container-live"
                  style="width: 100%; height: 100%; position: relative;"
                >
                  <button class="chatBtn" @click="isOpenChat = !isOpenChat">
                    Chat
                  </button>

                  <div
                    class="live-shop-container"
                    @click="fetchProductDetailsLive"
                  >
                    <img
                      class=""
                      width="35px"
                      height="35px"
                      src=".././assets/trolley.svg"
                      style="
                        background-color: white;
                        padding: 4px;
                        border-radius: 30%;
                      "
                    />
                  </div>

                  <div class="shareContainer" @click="isLiveSharePopup = true">
                    <img src="../assets/share.svg" alt="" />
                  </div>

                  <div class="viewsCount">
                    <i
                      class="fa fa-eye"
                      width="100px"
                      height="100px"
                      aria-hidden="true"
                    ></i>
                    <div class="viewcount">{{ this.allMembers.length }}</div>
                  </div>

                  <vodal
                    class="mymodal"
                    :width="200"
                    :height="215"
                    :show="isLiveSharePopup"
                    animation="rotate"
                    @hide="isLiveSharePopup = false"
                  >
                    <div
                      style="color: white; background-color: #999; padding: 5px"
                    >
                      Share On :
                    </div>
                    <br />
                    <div class="row center-it">
                      <div class="col-2 sm-icons">
                        <facebook
                          :url="pageurl"
                          :title="currentVideoObject.title"
                          scale="3"
                        ></facebook>
                      </div>
                      <div class="col-1 sm-icons"></div>
                      <div class="col-2 sm-icons">
                        <twitter
                          :url="pageurl"
                          :title="currentVideoObject.title"
                          scale="3"
                        ></twitter>
                      </div>
                    </div>
                    <br />
                    <div class="row center-it">
                      <div class="col-2 sm-icons">
                        <telegram
                          :url="pageurl"
                          :title="currentVideoObject.title"
                          scale="3"
                        ></telegram>
                      </div>
                      <div class="col-1 sm-icons"></div>
                      <div class="col-2 sm-icons">
                        <whats-app
                          :url="pageurl"
                          :title="currentVideoObject.title"
                          scale="3"
                        ></whats-app>
                      </div>
                    </div>
                  </vodal>
                </div>
              </div>
            </div>
            <vodal
              :width="350"
              :height="500"
              :show="showPromoCodeModal"
              animation="rotate"
              @hide="showPromoCodeModal = false"
            >
              <div style="height: 485px; overflow-y: scroll">
                <h4
                  style="
                    color: black !important;
                    padding-top: 0px;
                    font-weight: bold;
                    font-size: 20px;
                  "
                >
                  Select Your Options
                </h4>
                <br />
                <div v-for="(item, index) in productDetailsList" :key="index">
                  <div
                    class="card container"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      min-height: 40px;
                      font-size: 12px;
                      background-color: #ffd0d7;
                      border-radius: 2px;
                      border: 1px solid #ffd0d7;
                      cursor: pointer;
                    "
                    @click="productBuy(item)"
                  >
                    {{ item.name }}
                  </div>
                  <div
                    class="row"
                    style="
                      padding-top: 4px;
                      padding-bottom: 4px;
                    "
                  >
                    <!-- <div
                      v-for="(itemj, indexj) in item.ecommRedirect"
                      :key="indexj"
                      class="col-sm-6"
                      style="
                        max-width: 150px !important;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <img
                        style="width: 30px; width: 30px"
                        src=".././assets/icons/platform.png"
                      />

                      <button
                        style="border: none; width: 100px; font-size: 14px"
                        @click="redirectBuy(itemj.link)"
                      >
                        Buy on {{ itemj.platform }}
                      </button>
                    </div> -->
                  </div>
                  <br />
                </div>
              </div>
            </vodal>
          </div>
        </div>
      </div>
    </div>
    <div
      class="product-layout-wrapper"
      v-show="
        showProductObj && !isMobile && Object.keys(currLivestreamObj).length > 0
      "
    >
      <div style="display: flex; align-items: center; padding: 5px;">
        <span
          @click="
            showProductObj = false;
            product = {};
          "
          style="cursor: pointer; font-size: 16px; font-weight: 500;"
          >Close</span
        >
        <span
          style="display: flex; flex: 1; justify-content: center; font-weight: 700; font-size: 24px;"
          >Shop</span
        >
      </div>
      <div style="padding: 10px; height: 90vh; overflow-y: scroll">
        <img style="width: 100%" :src="product.url" />
        <p
          style="font-size: 16px; font-weight: 600; margin-bottom: 8px; line-height: 1.3;"
        >
          {{ product.title }}
        </p>
        <p style="font-size: 24px; font-weight: 700; line-height: 1;">
          $ {{ product.price }}
        </p>
        <a
          v-if="product.redirectUrl"
          :href="product.redirectUrl"
          class="buy-now-redirect"
          target="_blank"
          >Buy Now</a
        >
      </div>
    </div>
    <!-- <button type="button" @click="getInsta()">Click</button> -->
  </div>
</template>

<script>
try {
  window.scrollTo(0, 0);
} catch (e) {
  console.log(e);
}

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import Vodal from "vodal";
import "vodal/common.css";
import "vodal/rotate.css";
import { Facebook, Twitter, WhatsApp, Telegram } from "vue-socialmedia-share";
Vue.component(Vodal.name, Vodal);
Vue.use(VueAxios, axios);
import VueSimpleAlert from "vue-simple-alert";
Vue.use(VueSimpleAlert);
import AgoraRTC from "agora-rtc-sdk";
import AgoraRTM from "agora-rtm-sdk";
import { isMobile } from "mobile-device-detect";
var hidden, visibilityChange;
try {
  if (typeof document.hidden !== "undefined") {
    hidden = "hidden";
    visibilityChange = "visibilitychange";
  } else if (typeof document.msHidden !== "undefined") {
    hidden = "msHidden";
    visibilityChange = "msvisibilitychange";
  } else if (typeof document.webkitHidden !== "undefined") {
    hidden = "webkitHidden";
    visibilityChange = "webkitvisibilitychange";
  }
} catch (e) {
  console.log(e);
}

export default {
  name: "Video",
  props: {},
  components: {
    Facebook,
    Twitter,
    Telegram,
    WhatsApp,
  },
  data() {
    return {
      arr: [],
      allSearchProducts: [],
      allMembers: [],
      isScreenShare: false,
      appId: "467980bc7a524e4c8c3e8e18b7c5caf8",
      userId: "",
      token: "",
      searchProduct: "",
      isSearch: false,
      isShowProduct: false,
      isOpenChat: false,
      channel: {},
      videoDetailsList: [],
      clientObj: {},
      isLiveSharePopup: false,
      productDetailsList: [],
      currentVideoObject: {},
      isVideoMuted: true,
      displayMode: 0,
      myloader: "",
      isVideoLiked: false,
      isGiftIconClicked: false,
      viewsCurrVideo: 0,
      showPromoCodeModal: false,
      vendorUrl: "",
      brandname: "",
      brandId: "",
      products: {},
      showProductObj: false,
      product: {
        url: "",
        price: "",
        title: "",
        redirectUrl: "",
      },
      queueNo: "",
      isSharePopupVisible: false,
      videoUserName: "",
      followStatus: "Follow",
      currPage: 1,
      showVideoCallPopUp: false,
      callWidth: 0,
      callHeight: 0,
      isVideoCallActive: false,
      client: {},
      streamClient: {},
      localStream: {},
      liveLocalStream: {},
      isVideoEnabled: true,
      isAudioEnabledForVideoCall: true,
      resellerId: "",
      sellerId: "",
      liveVideoList: [],
      isLiveStreamActive: false,
      currLivestreamObj: {},
      currVideoType: "",
      isFollowPopupOpened: false,
      isMobile: isMobile,
      chatObject: [],
    };
  },
  watch: {
    // displayMode: function(val) {
    //   document.getElementById("sidebar").style.height =
    //     document.getElementById("videobox").clientHeight / 1.0233 + "px";
    //   if (val == 1) {
    //     document.getElementById("scrolldiv").style.height =
    //       document.getElementById("videobox").clientHeight / 1.0233 + "px";
    //   } else {
    //     document.getElementById("scrolldiv").style.height = "91vh";
    //   }
    // },

    isScreenShare() {
      this.startVideoCall();
    },
  },
  computed: {
    pageurl: function() {
      var url = window.location.origin + "/";
      if (sessionStorage.getItem("userId")) {
        url =
          url +
          this.currentVideoObject.video_id +
          "?uid=" +
          this.currLivestreamObj.id;
      } else {
        url = url + this.currentVideoObject.video_id;
      }
      return url;
    },
    brandurl: function() {
      var url = window.location.origin + "/assets/logo/logo.svg";
      return url;
    },
    videoPlayList: function() {
      if (this.videoDetailsList.length > 0) {
        var tempList = [];
        for (var i = 0; i < this.videoDetailsList.length; i++) {
          if (this.videoDetailsList[i].id != this.currentVideoObject.id) {
            tempList.push(this.videoDetailsList[i]);
          }
        }
        return tempList;
      } else {
        var x = [];
        return x;
      }
    },
  },
  mounted() {
    try {
      document.getElementById("sidebar").style.height =
        document.getElementById("videobox").clientHeight / 1.0233 + "px";
      window.scrollTo(0, 0);
    } catch (e) {
      console.log(e);
    }
    document.addEventListener(
      visibilityChange,
      this.handleVisibilityChange,
      false
    );
    if (this.$route.params.videoId) {
      this.getLiveStreamingEvents();
      
    }
  },

  created() {
    if (!this.$route.params.videoId) {
      this.initiateVideoList();
    }
    window.addEventListener("resize", this.myEventHandler);
    screen.orientation.lock("portrait-primary");
  },
  beforeDestroy() {
    this.disconnectCall();
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },

  methods: {
    async fetchData(){
      this.isOpenChat = true;
      this.showProductObj = true;
      
      const myheaders = {
        deviceId: sessionStorage.getItem("deviceId"),
        userId: sessionStorage.getItem("userId"),
        accessToken: sessionStorage.getItem("accessToken"),
      };
      
      await axios.get("https://api.wakaw.live/v1/api/videofeeds?brandId=" +
        this.brandId +
        "&type=Teaser", {headers: myheaders}).then((response) => {
          const teaserList = response.data.data.videoDetails;
          let matchedTeaser = null;
          teaserList.some((teaser) => {
            if (teaser.title === this.currLivestreamObj.title) {
              matchedTeaser = teaser;
              return true;
            }
            return false;
          });
          this.stopLoader();
          if (matchedTeaser) {
            return this.getLiveStreamProductList(matchedTeaser.video_id)
          }
          
        })
        console.log("oooppp");
          console.log(this.productDetailsList)
          console.log("oooppp");
          const random = Math.floor(Math.random() * this.productDetailsList.length);
          return this.showProduct(this.productDetailsList[random])
    },
    redirectToWebsite() {
      location.href = window.location.ancestorOrigins[0];
    },

    productBuy(item) {
      window.open(item.ecommRedirect, "_blank");
    },

    getInsta() {
      var data = {
        whitelisted_domains: ["https://agolde.wakaw.live/"],
      }.toString();
      var userId = "4959348200792596";
      var token =
        "IGQVJYUlIxWVBqbzg1WnpNWTBpbkZAibVBta01YelMwc3ZAaSGpydjNkQlk0cnJqYndkMDR4bUlmdWFaUlFDQzVzX2M3T3M0NzlZANVVNdi1VVTJDZAjZAybzRCbjhqamRvaEJ1TS0yOUZAPZAWJJSEtubk9BRgZDZD";
      axios
        .post(
          `https://graph.facebook.com/v12.0/${userId}/messenger_profile?access_token=${token}`,
          data
        )
        .then((res) => {
          console.log("res", res);
        });

      // axios.post('https://api.instagram.com/oauth/access_token', {
      //   "client_id": "3144786352503121",
      //   "client_secret": "bd557ce5cb08c39ccf6ae002ed6dc577",
      //   "code": "EAAPVYZAlvPukBAHEOLQaViwKIoV4S4nzyvvRFUpN02MNIwOSdOfKDCiLUbk98BZAnBLCBHDOUIZBSzkWCZCfltikfFuOm997pgKeBETP59PmXz6qbe4Al4ME4OaEmjgjTqcmHctjV7vb24EW8jlKVtsalNziPDgNdMGe6YS7evDjwWDOAsPlXnesMI4h9M47tvJG8xR3F9vYt3254KF7",
      //   "grant_type": "authorization_code",
      //   "redirect_uri": "https://socialsizzle.heroku.com/auth/"
      // }).then((res)=> {
      //   console.log(res);
      // })
    },

    getSearchEvents() {
      this.isSearch = true;
      this.isShowProduct = false;
      const myheaders = {
        deviceId: sessionStorage.getItem("deviceId"),
        userId: sessionStorage.getItem("userId"),
        accessToken: sessionStorage.getItem("accessToken"),
      };
      let apiUrl =
        "https://api.wakaw.live/v1/api/videofeeds?pageNumber=" +
        this.currPage +
        "&pageSize=10&brandId=" +
        this.brandId +
        "&type=Pre-recorded";
      axios
        .get(apiUrl, {
          headers: myheaders,
        })
        .then((res) => {
          this.allSearchProducts = res.data.data.videoDetails;
          this.allSearchProducts.forEach((i) => {
            axios
              .get(`https://api.wakaw.live/v1/api/products/` + i.video_id, {
                headers: myheaders,
              })
              .then((res) => {
                res.data.data.product_ids.forEach((i) => {
                  if (
                    i.name.toUpperCase().match(this.searchProduct.toUpperCase())
                  ) {
                    this.filterSearch(i.product_id);
                  }
                });
              })
              .catch((e) => {
                console.log(e);
              });
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },

    filterSearch(id) {
      this.arr = [];
      this.allSearchProducts.filter((i) => {
        i.product_ids.forEach((j) => {
          if (j.product_id === id) {
            this.arr.push(i);
            return true;
          }
        });
      });
    },
    fetchProductDetailsLive(hideShopModal = false) {
      this.startLoader();
      const myheaders = {
        deviceId: sessionStorage.getItem("deviceId"),
        userId: sessionStorage.getItem("userId"),
        accessToken: sessionStorage.getItem("accessToken"),
      };
      let apiUrl =
        "https://api.wakaw.live/v1/api/videofeeds?brandId=" +
        this.brandId +
        "&type=Teaser";
      return axios
        .get(apiUrl, {
          headers: myheaders,
        })
        .then((response) => {
          const teaserList = response.data.data.videoDetails;
          let matchedTeaser = null;
          teaserList.some((teaser) => {
            if (teaser.title === this.currLivestreamObj.title) {
              matchedTeaser = teaser;
              return true;
            }
            return false;
          });
          console.log("ooo")
          console.log("fetchProductDetailsLive", matchedTeaser.video_id);
          console.log("ooo")
          this.stopLoader();
          if (matchedTeaser) {
            return this.getLiveStreamProductList(matchedTeaser.video_id, hideShopModal);
          }
        })
        .catch((e) => {
          this.stopLoader();
          console.log(e);
        });
    },

    getLiveStreamProductList(videoId, hideShopModal = false) {
      this.startLoader();
      console.log('hideShopModal: ', hideShopModal)
      const myheaders = {
        deviceId: sessionStorage.getItem("deviceId"),
        userId: sessionStorage.getItem("userId"),
        accessToken: sessionStorage.getItem("accessToken"),
      };
      return axios
        .get(`https://api.wakaw.live/v1/api/products/` + videoId, {
          headers: myheaders,
        })
        .then((response) => {
          this.stopLoader();
          // this.liveProductDetailsList = response.data.data.product_ids;
          this.productDetailsList = response.data.data.product_ids;
          //if (!hideShopModal) {
            this.displayMode = 2;
          //}
          this.vendorUrl = response.data.data.product_ids[0].vendor_url;
        })
        .catch((e) => {
          this.stopLoader();
          console.log(e);
        });
    },
    unmuteStream() {},
    muteStream() {},
    changePageLive(currPage, pageChange) {
      let lowerLimit = currPage * 10;
      let upperLimit = (currPage + pageChange) * 10;
      this.startLoader();
      const myheaders = {
        deviceId: sessionStorage.getItem("deviceId"),
        userId: sessionStorage.getItem("userId"),
        accessToken: sessionStorage.getItem("accessToken"),
      };
      axios
        .post(
          `https://api.wakaw.live/v1/event/` + lowerLimit + `/` + upperLimit,
          {
            name: "",
            startDate: "",
            endDate: "",
            brandId: this.brandId,
          },
          { headers: myheaders }
        )
        .then((response) => {
          this.stopLoader();
          if (!response.data.data.length) {
            this.$confirm("No Further Live Events Found", "Error", "error");
          } else {
            this.displayMode = 3;
            this.currPage = currPage + pageChange;
            this.liveVideoList = response.data.data;
            let height = document.getElementById("videobox").clientHeight;
            document.getElementById("liveStreamDiv").style.height =
              height + "px";
          }
        })
        .catch((e) => {
          this.stopLoader();
          console.log(e);
        });
    },
    getLiveStreamingEvents() {
      
      this.currPage = 1;
      this.currVideoType = "";
      this.startLoader();
      const myheaders = {
        deviceId: sessionStorage.getItem("deviceId"),
        userId: sessionStorage.getItem("userId"),
        accessToken: sessionStorage.getItem("accessToken"),
      };
      axios
        .post(
          `https://api.wakaw.live/v1/event/0/100`,
          {
            name: "",
            startDate: "",
            endDate: "",
            brandId: this.brandId,
          },
          { headers: myheaders }
        )
        .then((response) => {
          console.log("getLiveStreamingEvents", response);
          this.stopLoader();
          this.liveVideoList = response.data.data;
          let height = document.getElementById("videobox").clientHeight;
          document.getElementById("liveStreamDiv").style.height = height + "px";
          if (this.liveVideoList.length <= 0) {
            this.$alert("No live shows at present ...Stay tuned!");
          } else {
            this.displayMode = 3;
            //this.isOpenChat = true;
            
            //this.showProductObj = true;
          }
          if (this.$route.params.videoId) {
            let index = this.liveVideoList.findIndex(
              (el) => el.id === this.$route.query.uid
            );
            this.playLiveVideo(index, this.liveVideoList[index]);
          }
        })
        .catch((e) => {
          this.stopLoader();
          console.log(e);
        });
    },
    getReSeller(appId, token, channel, userid, sellerId) {
      const myheaders = {
        deviceId: sessionStorage.getItem("deviceId"),
        userId: sessionStorage.getItem("userId"),
        accessToken: sessionStorage.getItem("accessToken"),
      };
      axios
        .get(`https://api.wakaw.live/v1/brandseller?brandId=` + this.brandId, {
          headers: myheaders,
        })
        .then((response) => {
          let list = response.data.data;
          let resellerObject = list.find((obj) => {
            return obj.sellerId == sellerId;
          });
          if (resellerObject) {
            this.resellerId = resellerObject.userId;
            this.sellerId = sellerId;
            this.joinRTM(appId, channel, this.resellerId);
          } else {
            this.$confirm("Something Went Wrong", "Error", "error")
              .then((r) => {
                console.log(r);
              })
              .catch(() => {});
          }
        });
    },
    muteCall() {
      this.isAudioEnabledForVideoCall = false;
      this.localStream.muteAudio();
    },
    unmuteCall() {
      this.isAudioEnabledForVideoCall = true;
      this.localStream.unmuteAudio();
    },
    disconnectCall() {
      try {
        this.localStream.close();
      } catch {
        //do nothing
      }

      let remDiv = document.getElementById("me");
      remDiv.innerHTML = "";
      try {
        this.client.leave();
      } catch {
        //do nothing
      }
      this.localStream = {};
      this.client = {};
      this.showVideoCallPopUp = false;
      this.isVideoCallActive = false;
      const myheaders = {
        deviceId: sessionStorage.getItem("deviceId"),
        userId: sessionStorage.getItem("userId"),
        accessToken: sessionStorage.getItem("accessToken"),
      };
      const myData = {
        brandId: this.brandId,
        sellerId: this.sellerId,
        call_status: "",
        video_id: this.currentVideoObject.id,
      };
      axios
        .patch("https://api.wakaw.live/v1/updateCallStatus", myData, {
          headers: myheaders,
        })
        .then((response) => {
          console.log(response);
          this.sellerId = "";
        })
        .catch((e) => {
          this.sellerId = "";
          console.log(e);
        });
    },
    disconnectCallQueue() {
      let remDiv = document.getElementById("me");
      remDiv.innerHTML = "";
      this.localStream = {};
      this.client = {};
      this.showVideoCallPopUp = false;
      this.isVideoCallActive = false;
    },
    disconnectStream() {
      this.streamClient.leave();
      this.streamClient = {};
      this.isLiveStreamActive = false;
      this.currLivestreamObj = {};
      window.location.reload();
    },
    disableVideo() {
      this.localStream.muteVideo();
      this.isVideoEnabled = false;
    },
    enableVideo() {
      this.localStream.unmuteVideo();
      this.isVideoEnabled = true;
    },
    startVideoCall() {
      const myheaders = {
        deviceId: sessionStorage.getItem("deviceId"),
        userId: sessionStorage.getItem("userId"),
        accessToken: sessionStorage.getItem("accessToken"),
      };

      axios
        .post(
          `https://api.wakaw.live/v1/videocall`,
          {
            userId: sessionStorage.getItem("userId"),
            brandId: this.brandId,
            videoId: this.currentVideoObject.video_id,
            products: "12334",
            channelId: "",
          },
          { headers: myheaders }
        )
        .then((response) => {
          let channel = "";
          if (response.data.data.status == "PENDING") {
            this.queueNo = response.data.data.position;
            channel = response.data.data.videoCallId;
            this.showVideoCallPopUp = true;
            let height = document.getElementById("videobox").clientHeight;
            this.isVideoCallActive = true;
            this.displayMode = 0;
            document.getElementById("callDiv").style.height = height + "px";
          } else {
            let token = response.data.data.agora_token;
            channel = response.data.data.videoCallId;
            let sellerId = response.data.data.sellerId;
            let uid = sessionStorage.getItem("userId");
            this.showVideoCallPopUp = true;
            let height = document.getElementById("videobox").clientHeight;
            this.isVideoCallActive = true;
            this.displayMode = 0;
            document.getElementById("callDiv").style.height = height + "px";
            this.joinChannel(
              "467980bc7a524e4c8c3e8e18b7c5caf8",
              token,
              channel,
              uid,
              sellerId
            );

            // set call status to ringing
            const myData = {
              brandId: this.brandId,
              sellerId: this.sellerId,
              call_status: "RINGING",
              video_id: this.currentVideoObject.id,
            };

            axios
              .patch("https://api.wakaw.live/v1/updateCallStatus", myData, {
                headers: myheaders,
              })
              .then((response) => {
                console.log("video call started for seller: ", response);
              })
              .catch((e) => {
                console.log(e);
              });
          }
        });
    },
    getUpcomingEvents() {
      this.getTeasers(this.currPage, this.brandId, 0);
    },
    joinChannel(appId, token, channel, userid, sellerId) {
      this.client = AgoraRTC.createClient({
        mode: "live",
        codec: "h264",
      });
      this.client.init(
        appId,
        () => console.log("AgoraRTC client initialized"),
        this.handleFail
      );
      var vm = this;
      vm.subscribeStreamEvents();
      this.client.join(
        token,
        channel,
        userid,
        () => {
          if (!this.isScreenShare) {
            vm.localStream = AgoraRTC.createStream({
              streamID: userid,
              audio: true,
              video: true,
              screen: false,
            });
          } else {
            vm.localStream = AgoraRTC.createStream({
              streamID: userid,
              audio: false,
              video: false,
              screen: true,
            });
          }
          document.getElementById("screenShare").onclick = () => {
            this.isScreenShare = true;
          };
          vm.localStream.on("stopScreenSharing", () => {
            vm.isScreenShare = false;
          });
          let remDiv = document.getElementById("me");
          remDiv.innerHTML = "";
          vm.localStream.init(function() {
            vm.localStream.setVideoEncoderConfiguration({
              resolution: {
                width: 360,
                height: 640,
              },
            });
            vm.localStream.play("me");
            //Publishes the stream to the channel
            vm.client.publish(vm.localStream, vm.handleFail);
            let myDiv = document.getElementById(
              "video" + vm.localStream.getId()
            );
            myDiv.style.position = "relative";
            vm.getReSeller(appId, token, channel, userid, sellerId);
          }, vm.handleFail);
        },
        vm.handleFail
      );
    },

    promoShow() {
      const myheaders = {
        deviceId: sessionStorage.getItem("deviceId"),
        userId: sessionStorage.getItem("userId"),
        accessToken: sessionStorage.getItem("accessToken"),
      };
      axios
        .get(
          `https://api.wakaw.live/v1/api/products/` +
            this.currentVideoObject.video_id,
          {
            headers: myheaders,
          }
        )
        .then((response) => {
          this.productDetailsList = response.data.data.product_ids;
          this.showPromoCodeModal = true;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    joinRTM(appId, channel, resellerId) {
      const myheaders = {
        deviceId: sessionStorage.getItem("deviceId"),
        userId: sessionStorage.getItem("userId"),
        accessToken: sessionStorage.getItem("accessToken"),
      };
      axios
        .get(`https://api.wakaw.live/v1/rtm/token`, {
          headers: myheaders,
        })
        .then((response) => {
          const clientRtm = AgoraRTM.createInstance(appId);
          clientRtm
            .login({
              token: response.data.data.agoraRtmToken,
              uid: sessionStorage.getItem("userId"),
            })
            .then(() => {
              console.log("AgoraRTM client login success");
              let rtmObj = clientRtm.createLocalInvitation(resellerId);
              rtmObj.content = channel;
              rtmObj.send();
            })
            .catch((err) => {
              console.log("AgoraRTM client login failure", err);
            });
        });
    },
    subscribeStreamEventsForLiveStreaming() {
      var vm = this;
      vm.streamClient.on("stream-added", function(evt) {
        vm.streamClient.subscribe(evt.stream, vm.handleFail);
        this.liveLocalStream = evt.stream;
      });
      //When you subscribe to a stream
      vm.streamClient.on("stream-subscribed", function(evt) {
        this.liveLocalStream = evt.stream;
        let stream = evt.stream;
        vm.addVideoStreamLive(stream.getId());
        stream.play(stream.getId());
        vm.addCanvasLive(stream.getId());
        vm.isLiveStreamActive = true;
      });
      vm.streamClient.on("streamInjectedStatus", function(evt) {
        console.log(JSON.stringify(evt));
      });
      //When a person is removed from the stream
      vm.streamClient.on("stream-removed", vm.removeVideoStreamLive);
      vm.streamClient.on("peer-leave", vm.removeVideoStreamLive);
    },
    subscribeStreamEvents() {
      var vm = this;
      vm.client.on("stream-added", function(evt) {
        vm.client.subscribe(evt.stream, vm.handleFail);
      });
      //When you subscribe to a stream
      vm.client.on("stream-subscribed", function(evt) {
        let stream = evt.stream;
        vm.addVideoStream(stream.getId());
        stream.play(stream.getId());
        vm.addCanvas(stream.getId());
      });
      //When a person is removed from the stream
      vm.client.on("stream-removed", vm.removeVideoStream);
      vm.client.on("peer-leave", vm.removeVideoStream);
    },
    addVideoStream(streamId) {
      let streamDiv = document.createElement("div"); // Create a new div for every stream
      streamDiv.id = streamId;
      streamDiv.style.position = "fixed"; // Assigning id to div
      streamDiv.style.transform = "rotateY(180deg)"; // Takes care of lateral inversion (mirror image)
      let remoteContainer = document.getElementById("remote-container");
      remoteContainer.appendChild(streamDiv); // Add new div to container
    },
    addVideoStreamLive(streamId) {
      let streamDiv = document.createElement("div"); // Create a new div for every stream
      streamDiv.id = streamId;
      streamDiv.style.position = "fixed"; // Assigning id to div
      streamDiv.style.transform = "rotateY(180deg)"; // Takes care of lateral inversion (mirror image)
      let remoteContainer = document.getElementById("remote-container-live");
      remoteContainer.appendChild(streamDiv); // Add new div to container
    },
    removeVideoStream(evt) {
      let stream = evt.stream;
      stream.stop();
      let remDiv = document.getElementById(stream.getId());
      remDiv.parentNode.removeChild(remDiv);
      console.log("Remote stream is removed " + stream.getId());
    },
    removeVideoStreamLive(evt) {
      let stream = evt.stream;
      stream.stop();
      let remDiv = document.getElementById(stream.getId());
      remDiv.parentNode.removeChild(remDiv);
      console.log("Remote stream is removed " + stream.getId());
      this.streamClient.leave();
      this.streamClient = {};
      this.isLiveStreamActive = false;
      window.location.reload();
    },
    addCanvas(streamId) {
      let canvas = document.createElement("canvas");
      canvas.id = "canvas" + streamId;
      let canvasContainer = document.getElementById("canvas-container");
      canvasContainer.appendChild(canvas);
      let ctx = canvas.getContext("2d");
      let video = document.getElementById(`video${streamId}`);
      video.addEventListener("loadedmetadata", function() {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
      });

      video.addEventListener(
        "play",
        function() {
          var $this = this; //cache
          (function loop() {
            if (!$this.paused && !$this.ended) {
              ctx.drawImage($this, 0, 0);
              setTimeout(loop, 1000 / 30); // drawing at 30fps
            }
          })();
        },
        0
      );
    },
    addCanvasLive(streamId) {
      let canvas = document.createElement("canvas");
      canvas.id = "canvas" + streamId;
      let canvasContainer = document.getElementById("canvas-container-live");
      canvasContainer.appendChild(canvas);
      let ctx = canvas.getContext("2d");
      let video = document.getElementById(`video${streamId}`);
      video.addEventListener("loadedmetadata", function() {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
      });

      video.addEventListener(
        "play",
        function() {
          var $this = this; //cache
          (function loop() {
            if (!$this.paused && !$this.ended) {
              ctx.drawImage($this, 0, 0);
              setTimeout(loop, 1000 / 30); // drawing at 30fps
            }
          })();
        },
        0
      );
    },
    changePage(currPage, pageChange) {
      this.getVideoFeedForBrandPageChange(currPage, this.brandId, pageChange);
    },
    handleVisibilityChange() {
      var videoElement = document.getElementById("video-elem");
      if (document[hidden] && videoElement.style.display != "none") {
        videoElement.pause();
      } else {
        videoElement.play();
      }
    },
    recordVideoShare(uid, vId) {
      const myheaders = {
        deviceId: sessionStorage.getItem("deviceId"),
        userId: sessionStorage.getItem("userId"),
        accessToken: sessionStorage.getItem("accessToken"),
      };
      axios
        .post(
          `https://api.wakaw.live/v1/api/analytics/videoshare/view`,
          {
            shared_by_user_id: uid,
            videoId: vId,
          },
          { headers: myheaders }
        )
        .then((response) => {
          console.log(response);
        });
    },
    setFollowStatus() {
      const myheaders = {
        deviceId: sessionStorage.getItem("deviceId"),
        userId: sessionStorage.getItem("userId"),
        accessToken: sessionStorage.getItem("accessToken"),
      };
      axios
        .get(
          `https://api.wakaw.live/v1/api/follow/status?followeeId=` +
            this.currentVideoObject.user_id,
          {
            headers: myheaders,
          }
        )
        .then((response) => {
          if (response.data.data.status) {
            this.followStatus = "Following";
          }
        });
    },
    followUser() {
      const myheaders = {
        deviceId: sessionStorage.getItem("deviceId"),
        userId: sessionStorage.getItem("userId"),
        accessToken: sessionStorage.getItem("accessToken"),
      };
      axios
        .post(
          `https://api.wakaw.live/v1/api/follow`,
          {
            following_id: this.currentVideoObject.user_id,
          },
          { headers: myheaders }
        )
        .then((response) => {
          if (response.data.status == "success") {
            this.followStatus = "Following";
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    showProduct(item) {
      this.showProductObj = true;
      this.isShowProduct = false;
      this.displayMode = 0;
      this.product.url = item.images[0];
      this.product.title = item.name;
      this.product.price = item.variantMrp;
      this.product.redirectUrl = item.ecommRedirect;
    },
    getVideoAnalytics() {
      const myheaders = {
        deviceId: sessionStorage.getItem("deviceId"),
        userId: sessionStorage.getItem("userId"),
        accessToken: sessionStorage.getItem("accessToken"),
      };
      axios
        .post(
          `https://api.wakaw.live/v1/api/analytics/video/` +
            this.currentVideoObject.video_id +
            "/view",
          {
            tagged_orders: [],
            brandId: this.$route.params.brand,
          },
          { headers: myheaders }
        )
        .then((response) => {
          console.log(response);
          axios
            .get(
              `https://api.wakaw.live/v1/api/analytics/video/` +
                this.currentVideoObject.video_id,
              {
                headers: myheaders,
              }
            )
            .then((response) => {
              var info = response.data.data;
              if (info.viewsCount) {
                this.viewsCurrVideo = info.viewsCount;
              }
              if (
                info.UserStatus.likes == true ||
                info.UserStatus.likes == "true"
              ) {
                this.isVideoLiked = true;
              }
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    likeCurrVideo() {
      const myheaders = {
        deviceId: sessionStorage.getItem("deviceId"),
        userId: sessionStorage.getItem("userId"),
        accessToken: sessionStorage.getItem("accessToken"),
      };
      axios
        .post(
          `https://api.wakaw.live/v1/api/analytics/video/` +
            this.currentVideoObject.video_id +
            "/view",
          {
            tagged_orders: [],
            brandId: this.brandId,
          },
          { headers: myheaders }
        )
        .then(() => {
          axios
            .post(
              `https://api.wakaw.live/v1/api/analytics/video/` +
                this.currentVideoObject.video_id +
                "/like",
              {},
              { headers: myheaders }
            )
            .then((response) => {
              console.log(response);
              this.isVideoLiked = true;
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    openGiftPromo() {
      this.isGiftIconClicked = true;
      this.displayMode = 0;
      this.showPromoCodeModal = true;
    },
    startLoader() {
      this.myloader = this.$loading.show({
        canCancel: false,
        color: "#000000",
      });
    },
    stopLoader() {
      this.myloader.hide();
    },
    redirectBuy(url) {
      let finalUrl = url + "?utm_source=wakaw";
      window.open(finalUrl, "_newtab");
    },

    fetchProductDetailsInitial(hideShopModal = false) {
      const myheaders = {
        deviceId: sessionStorage.getItem("deviceId"),
        userId: sessionStorage.getItem("userId"),
        accessToken: sessionStorage.getItem("accessToken"),
      };
      let that = this
      let apiUrl =
        "https://api.wakaw.live/v1/api/videofeeds?brandId=" +
        this.brandId +
        "&type=Teaser";
      return axios
        .get(apiUrl, {
          headers: myheaders
        }) .then(() => {

          let matchedTeaser = null;


        that.stopLoader();
         
          if (matchedTeaser) {
            return this.clientLogin(matchedTeaser.video_id, hideShopModal);
          }
        })

        // .then((response) => {
        //   this.productDetailsList = response.data.data.product_ids;
        //   this.vendorUrl = response.data.data.product_ids[0].vendor_url;
        //   this.brandname = response.data.data.product_ids[0].brand_name;
        // })
        .catch((e) => {
          console.log(e);
        });
    },
    // fetchProductDetailsInitial(hideShopModal = false) {
    //   const myheaders = {
    //     deviceId: sessionStorage.getItem("deviceId"),
    //     userId: sessionStorage.getItem("userId"),
    //     accessToken: sessionStorage.getItem("accessToken"),
    //   };
    //   let apiUrl =
    //     "https://api.wakaw.live/v1/api/videofeeds?brandId=" +
    //     this.brandId +
    //     "&type=Teaser";
    //   return axios
    //     .get(apiUrl, {
    //       headers: myheaders,
    //     });

    //     this.stopLoader();
         
    //       if (matchedTeaser) {
    //         return this.clientLogin(matchedTeaser.video_id, hideShopModal);
    //       }
    //     })

    //     // .then((response) => {
    //     //   this.productDetailsList = response.data.data.product_ids;
    //     //   this.vendorUrl = response.data.data.product_ids[0].vendor_url;
    //     //   this.brandname = response.data.data.product_ids[0].brand_name;
    //     // })
    //     .catch((e) => {
    //       console.log(e);
    //     });
    // },

    fetchProductDetails() {
      this.startLoader();
      const myheaders = {
        deviceId: sessionStorage.getItem("deviceId"),
        userId: sessionStorage.getItem("userId"),
        accessToken: sessionStorage.getItem("accessToken"),
      };
      axios
        .get(
          `https://api.wakaw.live/v1/api/products/` +
            this.currentVideoObject.video_id,
          {
            headers: myheaders,
          }
        )
        .then((response) => {
          this.stopLoader();
          this.isSearch = false;
          console.log("fetchProductDetails", response.data.data);
          this.productDetailsList = response.data.data.product_ids;
          this.displayMode = 2;
          this.vendorUrl = response.data.data.product_ids[0].vendor_url;
        })
        .catch((e) => {
          this.stopLoader();
          console.log(e);
        });
    },
    playTheVideo() {
      this.isVideoMuted = false;
      var vid = document.getElementById("video-elem");
      vid.muted = false;
    },
    pauseTheVideo() {
      this.isVideoMuted = true;
      var vid = document.getElementById("video-elem");
      vid.muted = true;
    },
    fullScreenVideo() {
      var elem = document.getElementById("video-elem");
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    },
    playLiveVideo(index, item) {
      this.currLivestreamObj = item;
      const myheaders = {
        deviceId: sessionStorage.getItem("deviceId"),
        userId: sessionStorage.getItem("userId"),
        accessToken: sessionStorage.getItem("accessToken"),
      };
      axios
        .post(
          `https://api.wakaw.live/v1/event/join`,
          {
            eventId: item.id,
          },
          { headers: myheaders }
        )
        .then((response) => {
          this.joinLiveStream(
            response.data.data.channelName,
            response.data.data.token,
            sessionStorage.getItem("userId")
          );
          this.clientLogin(response.data.data.channelName);
        });
    },
    joinLiveStream(channel, token, userid) {
      this.streamClient = AgoraRTC.createClient({
        mode: "live",
        codec: "h264",
        screen: true,
      });
      this.streamClient.setClientRole(
        "audience",
        function() {
          console.log("setaudience success");
        },
        function(e) {
          console.log("setaudience failed", e);
        }
      );
      //creation of a channel
      this.streamClient.init(
        "467980bc7a524e4c8c3e8e18b7c5caf8",
        () => console.log("AgoraRTC client initialized"),
        this.handleFail
      );
      var vm = this;
      this.subscribeStreamEventsForLiveStreaming();
      this.streamClient.join(
        token,
        channel,
        userid,
        (uid) => {
          console.log(uid);
          vm.isLiveStreamActive = true;
          vm.displayMode = 0;
        },
        vm.handleFail
      );
    },

    clientLogin(channelName , videoId) {      
      var userId = sessionStorage.getItem("userId");
      const myheaders = {
        deviceId: sessionStorage.getItem("deviceId"),
        userId: sessionStorage.getItem("userId"),
        accessToken: sessionStorage.getItem("accessToken"),
      };
     return axios
        .get(`https://api.wakaw.live/v1/rtm/token` + videoId, { headers: myheaders })
        .then((res) => {
          let clientObj = AgoraRTM.createInstance(this.appId);
          clientObj
            .login({
              uid: userId,
              token: res.data.data.agoraRtmToken,
            })
            .then(() => {
              let channel = clientObj.createChannel(channelName);
              channel.on("ChannelMessage", (message, memberId) => {
                var msgObj = JSON.parse(message.text);
                console.log("MemberId", memberId, msgObj);
                const chatMessage = msgObj?.message
                if (chatMessage?.startsWith('##')) {
                  const productId = chatMessage.split('##')?.[1]
                  // const productId = "ec3e29a9-a1ed-459b-9966-d97886e5b672"
                  console.log("flash productId", productId);
                  this.fetchProductDetailsLive(true).then(() => {
                  console.log("flash productId 1", productId, this.productDetailsList);
                    let flashedProduct = null
                    this.productDetailsList.some(pd => {
                      console.log("flash productId 2", pd);
                      if (pd.product_id === productId) {
                      console.log("flash productId 3", pd);
                        flashedProduct = pd
                        return true
                      }

                      return false
                    })
                    console.log('flashedProduct: ', flashedProduct)
                    flashedProduct && this.showProduct(flashedProduct)
                  })
                } else {
                  this.chatObject.push(msgObj);
                }
              });

              channel
                .join()
                .then(() => {
                  setInterval(() => {
                    this.getAllMembers(channel);
                  }, 2000);
                  document.getElementById(
                    "send_channel_message"
                  ).onclick = async () => {
                    await this.sendChatMessage(channel);
                  };
                  document
                    .getElementById("channelMessage")
                    .addEventListener("keyup", async (event) => {
                      if (event.key === "Enter") {
                        await this.sendChatMessage(channel);
                        return
                      }
                    });
                })
                .catch((err) => {
                  console.log(err);
                });
            })
            .catch((err) => {
              console.log(err);
            })
            .catch((err) => {
              console.log(err);
            });
        });
    },
    async sendChatMessage(channel) {
      var channelMessage = document.getElementById("channelMessage");
      var channelMessageValue = channelMessage?.value;
      var messageObj = {
        message: channelMessageValue,
        image:
          "https://static.vecteezy.com/system/resources/previews/002/318/271/non_2x/user-profile-icon-free-vector.jpg",
        name: sessionStorage.getItem("userName"),
      };
      var messageStr = JSON.stringify(messageObj);
      if (channel != null) {
        await channel
          .sendMessage({ text: messageStr, messageType: "TEXT" })
          .then(() => {
            this.chatObject.push(messageObj);
            channelMessage.value = "";
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    isDisable() {
      const myheaders = {
        deviceId: sessionStorage.getItem("deviceId"),
        userId: sessionStorage.getItem("userId"),
        accessToken: sessionStorage.getItem("accessToken"),
      };
      axios
        .get(
          `http://stage-public-alb-1910504344.ap-south-1.elb.amazonaws.com/api/v1/campaign/list`,
          { headers: myheaders }
        )
        .then((res) => {
          console.log("Campaign Response", res);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getAllMembers(channel) {
      channel.getMembers().then(async (memberId) => {
        this.allMembers = memberId;
      });
    },

    playVideo(index, i) {
      if (this.isLiveStreamActive) {
        this.streamClient.leave();
        this.streamClient = {};
        document.getElementById("remote-container-live").innerHTML = "";
        document.getElementById("canvas-container-live").innerHTML = "";
        this.isLiveStreamActive = false;
      }
      this.currentVideoObject = i;
      var video = document.getElementById("video-elem");
      var source = document.createElement("source");

      source.setAttribute("src", i.videoUrls.MP4[0]);
      video.appendChild(source);
      video.load();
      video.play();
      try {
        this.getVideoAnalytics();
      } catch (e) {
        console.log(e);
      }
      this.isVideoLiked = false;
      this.videoUserName = i.username;
      this.setFollowStatus();
    },
    initiateVideoList() {
      try {
        if (this.$route.query.uid && this.$route.params.videoId) {
          this.recordVideoShare(
            this.$route.query.uid,
            this.$route.params.videoId
          );
        }
      } catch (e) {
        console.log(e);
      }
      const myheaders = {
        deviceId: sessionStorage.getItem("deviceId"),
        userId: sessionStorage.getItem("userId"),
        accessToken: sessionStorage.getItem("accessToken"),
      };
      var myDomain = window.location.origin;
      if (window.location.hostname == "localhost") {
        myDomain = "https://target.wakaw.live";
      }

      axios
        .get(`https://api.wakaw.live/v1/brand?brandDomain=` + myDomain, {
          headers: myheaders,
        })
        .then((response) => {
          this.brandId = response.data.id;
          this.getVideoFeedForBrand(this.currPage, this.brandId, 0);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getVideoFeedForBrand(currPage, brandID, pageChange) {
      this.currVideoType = "";
      this.startLoader();
      const myheaders = {
        deviceId: sessionStorage.getItem("deviceId"),
        userId: sessionStorage.getItem("userId"),
        accessToken: sessionStorage.getItem("accessToken"),
      };
      let apiUrl =
        "https://api.wakaw.live/v1/api/videofeeds?pageNumber=" +
        currPage +
        "&pageSize=10&brandId=" +
        brandID +
        "&type=Pre-recorded";
      axios
        .get(apiUrl, {
          headers: myheaders,
        })
        .then((response) => {
          this.stopLoader();
          this.videoDetailsList = response.data.data.videoDetails;
          if (this.videoDetailsList.length == 0) {
            //this.$router.push({ path: '/404notfound'});
          } else {
            this.currPage = this.currPage + pageChange;
            var mydiv = document.getElementById("scrolldiv");
            mydiv.scrollTop = 0;
          }
          try {
            this.videoUserName = this.videoDetailsList[0].username;
          } catch (e) {
            console.log(e);
          }
          this.currentVideoObject = this.videoDetailsList[0];

          try {
            this.getVideoAnalytics();
          } catch (e) {
            console.log(e);
          }
          try {
            this.setFollowStatus();
          } catch (e) {
            console.log(e);
          }
          this.fetchProductDetailsInitial();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getTeasers(currPage, brandID, pageChange) {
      var myPage = currPage + pageChange;
      this.startLoader();
      const myheaders = {
        deviceId: sessionStorage.getItem("deviceId"),
        userId: sessionStorage.getItem("userId"),
        accessToken: sessionStorage.getItem("accessToken"),
      };
      let apiUrl =
        "https://api.wakaw.live/v1/api/videofeeds?pageNumber=" +
        myPage +
        "&pageSize=10&brandId=" +
        brandID +
        "&type=Teaser";
      axios
        .get(apiUrl, {
          headers: myheaders,
        })
        .then((response) => {
          this.stopLoader();
          if (response.data.data.videoDetails.length > 0) {
            this.videoDetailsList = response.data.data.videoDetails;
            this.currPage = this.currPage + pageChange;
            var mydiv = document.getElementById("scrolldiv");
            mydiv.scrollTop = 0;
            this.displayMode = 4;
          } else {
            this.$confirm("No Teasers Found", "Error", "error")
              .then((r) => {
                console.log(r);
              })
              .catch(() => {});
          }
        })
        .catch((e) => {
          this.stopLoader();
          console.log(e);
        });
    },
    getVideoFeedForBrandPageChange(currPage, brandID, pageChange) {
      var myPage = currPage + pageChange;
      this.startLoader();
      const myheaders = {
        deviceId: sessionStorage.getItem("deviceId"),
        userId: sessionStorage.getItem("userId"),
        accessToken: sessionStorage.getItem("accessToken"),
      };
      let apiUrl =
        "https://api.wakaw.live/v1/api/videofeeds?pageNumber=" +
        myPage +
        "&pageSize=10&brandId=" +
        brandID;
      if (this.currVideoType == "Teaser") {
        apiUrl = apiUrl + "&type=Teaser";
      } else if (this.currVideoType == "") {
        apiUrl = apiUrl + "&type=Pre-recorded";
      }
      axios
        .get(apiUrl, {
          headers: myheaders,
        })
        .then((response) => {
          this.stopLoader();
          if (response.data.data.length > 0) {
            this.videoDetailsList = response.data.data;
            this.currPage = this.currPage + pageChange;
            var mydiv = document.getElementById("scrolldiv");
            mydiv.scrollTop = 0;
          } else {
            this.$alert("No Further videos found");
          }
        })
        .catch((e) => {
          this.stopLoader();
          console.log(e);
        });
    },
    myEventHandler(e) {
      console.log(e);
      document.getElementById("sidebar").style.height =
        document.getElementById("videobox").clientHeight / 1.0233 + "px";
    },
  },
};
</script>

<style scoped>
.layout-wrapper {
  display: flex;
}

.mobile_view.icons {
  position: absolute;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.header {
  position: absolute;
  height: 55px;
  left: 0%;
  right: 0%;
  top: 0px;
  display: inline;
  background: #424242;
  box-sizing: border-box;
}
.feedTitle {
  height: 24px;
  margin: 0;
  margin-left: -150px;
  width: fit-content;
  padding: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: white;
  align-items: center;
}
.vjs_video_578-dimensions {
  width: 500px !important;
  height: 576px !important;
}
::-webkit-scrollbar {
  width: 0px !important; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: transparent !important;
}
.container-fluid {
  padding: 0px;
}
.row {
  margin-left: 0;
  margin-right: 0;
}
.videoSection {
  position: relative;
  overflow: hidden;
}
.callsection {
  position: relative;
  overflow: hidden;
}
video {
  width: 100%;
  height: 98vh;
  object-fit: cover !important;
  transform: scale(1.22, 1);
}

@media (max-width: 580px) {
  .shoe_box {
    width: 35%;
  }
}

@media (max-width: 389px) {
  .mobile_view {
    width: 100vw !important;
  }
}
@media (min-height: 500px) and (max-width: 650px) {
  .mobile_view {
    width: 100vw !important;
  }
  video {
    transform: scale(1.39, 1);
    width: 100%;
  }
}
@media (min-width: 650px) {
  .mobile_view {
    width: 390px !important;
    padding-bottom: 25px;
  }
}
@media (min-width: 700px) {
  .mobile_view {
    padding-left: 0px !important;
  }
}
.mobile_view {
  width: 389px;
  height: fit-content;
  /*padding-top:4px;*/
  padding-bottom: 9px;
  box-shadow: 0 8px 15px rgba(14, 14, 14, 0.175);
  margin: 0 auto;
  border: 1px solid #020310;
  padding-left: 1px;
  padding-right: 1px;
  /*padding-top: 3px;*/
  max-height: 100%;
}
.shoe_box {
  position: relative;
  z-index: 10;
  width: fit-content;
  max-width: 35%;
}
.video_box {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
}
.videoSection img.expand {
  position: absolute;
  z-index: 111;
  /* left: 0px; */
  top: 65px;
  right: 9px;
  color: white;
  margin: 1%;
  font-size: 30px;
  cursor: pointer;
}
div.viewcount {
  position: absolute;
  z-index: 111;
  top: 2.1%;
  right: 36%;
  color: white;
  margin: 1%;
  font-size: 16px;
}
div.shop {
  position: absolute;
  z-index: 111;
  top: 68.1%;
  right: 1%;
  color: white;
  margin: 1%;
  font-size: 16px;
}

div.videoIcon {
  position: absolute;
  z-index: 111;
  top: 57.1%;
  right: 1%;
  color: white;
  margin: 1%;
  font-size: 16px;
}

.videoSection i.fa-eye {
  position: absolute;
  z-index: 111;
  top: 2%;
  right: 43%;
  color: white;
  margin: 1%;
  font-size: 25px;
  cursor: pointer;
}
.videoSection img.brandlogo {
  position: absolute;
  z-index: 111;
  top: 0px;
  right: 0%;
  color: white;
  margin: 1%;
  font-size: 30px;
  cursor: pointer;
}
.videoSection img.wtvlogo {
  position: absolute;
  z-index: 111;
  top: 0px;
  left: 1%;
  color: white;
  margin: 1%;
  font-size: 30px;
  cursor: pointer;
}
.videoSection img.mute {
  position: absolute;
  z-index: 111;
  /* left: 0px; */
  top: 120px;
  right: 8px;
  color: white;
  margin: 1%;
  font-size: 35px;
  cursor: pointer;
}
.videoSection img.speak {
  position: absolute;
  z-index: 111;
  /* left: 0px; */
  top: 120px;
  right: 8px;
  color: white;
  margin: 1%;
  font-size: 35px;
  cursor: pointer;
}
.videoSection img.camera {
  position: absolute;
  z-index: 111;
  /* left: 0px; */
  top: 52%;
  right: 1%;
  color: white;
  margin: 1%;
  font-size: 39px;
  cursor: pointer;
}
.streamdiv img.camera {
  position: absolute;
  z-index: 111;
  /* left: 0px; */
  top: 44%;
  right: 9px;
  color: white;
  margin: 1%;
  font-size: 39px;
  cursor: pointer;
}
.streamdiv img.giftlive {
  position: absolute;
  z-index: 111;
  /* left: 0px; */
  top: 34%;
  right: 9px;
  color: white;
  margin: 1%;
  font-size: 39px;
  cursor: pointer;
}
.videoSection img.like {
  position: absolute;
  z-index: 111;
  /* left: 0px; */
  top: 69%;
  right: 9px;
  color: white;
  margin: 1%;
  font-size: 38px;
  cursor: pointer;
}
.videoSection img.gift {
  position: absolute;
  z-index: 111;
  /* left: 0px; */
  top: 45%;
  right: 9px;
  color: white;
  margin: 1%;
  font-size: 38px;
  cursor: pointer;
}
.videoSection img.vcall {
  position: absolute;
  z-index: 111;
  /* left: 0px; */
  top: 36%;
  right: 5px;
  color: white;
  margin: 1%;
  font-size: 40px;
  cursor: pointer;
  border-radius: 50%;
}
img.playv {
  border-radius: 50%;
}
.videoSection img.share {
  position: absolute;
  z-index: 111;
  /* left: 0px; */
  top: 77%;
  right: 9px;
  color: rgb(136, 133, 133);
  margin: 1%;
  font-size: 39px;
  cursor: pointer;
}
.videoSection img.cart {
  position: absolute;
  z-index: 111;
  /* left: 0px; */
  top: 62%;
  right: 1%;
  color: white;
  margin: 1%;
  font-size: 40px;
  cursor: pointer;
}
.streamdiv img.cart {
  position: absolute;
  z-index: 111;
  /* left: 0px; */
  top: 54%;
  right: 9px;
  color: white;
  margin: 1%;
  font-size: 40px;
  cursor: pointer;
}
.videoSection .userprofile {
  position: absolute;
  right: 8px;
  bottom: 48px;
  z-index: 111;
  color: white;
  cursor: pointer;
  width: fit-content;
  padding-right: 7px;
  background: rgba(0, 0, 0, 0.7);
  height: 46px;
  border-top-right-radius: 23px;
  border-bottom-right-radius: 23px;
  border-top-left-radius: 23px;
  border-bottom-left-radius: 23px;
  display: flex;
  align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
}
.videoSection div.queueSection {
  position: absolute;
  z-index: 111;
  /* left: 0px; */
  top: 24%;
  right: 0px;
  color: white;
  cursor: pointer;
  max-width: 340px;
  width: fit-content;
  padding-right: 7px;
  background: black;
  opacity: 0.6;
  height: 46px;
  border-top-left-radius: 23px;
  border-bottom-left-radius: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
}
button:active {
  border: none;
}
.callsection img.vcallmute {
  position: absolute;
  z-index: 111;
  /* left: 0px; */
  bottom: 2%;
  left: 20%;
  color: white;
  margin: 1%;
  font-size: 40px;
  cursor: pointer;
}
.callsection img.vuser {
  position: absolute;
  z-index: 111;
  /* left: 0px; */
  bottom: 2%;
  left: 43%;
  color: white;
  margin: 1%;
  font-size: 40px;
  cursor: pointer;
}
.callsection img.vcomment {
  position: absolute;
  z-index: 111;
  /* left: 0px; */
  bottom: 2%;
  right: 35%;
  color: white;
  margin: 1%;
  font-size: 40px;
  cursor: pointer;
}
.callsection img.calld {
  position: absolute;
  z-index: 111;
  /* left: 0px; */
  bottom: 2%;
  right: 20%;
  color: white;
  margin: 1%;
  font-size: 40px;
  cursor: pointer;
}
.callsection img.calld1 {
  position: absolute;
  z-index: 111;
  /* left: 0px; */
  top: 1%;
  right: 9px;
  color: white;
  margin: 1%;
  font-size: 40px;
  cursor: pointer;
}
.videoSection img.userprofileicon {
  border-radius: 50%;
  opacity: 1;
  top: 12%;
  margin-left: 8px;
  margin-right: 3px;
}
.queue {
  border-top-left-radius: 23px;
  border-bottom-left-radius: 23px;
  opacity: 1;
  top: 12%;
  margin-right: 5px;
  background-color: #867837;
  width: 70px;
  height: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hh img.playv {
  position: absolute;
  z-index: 111;
  /* left: 0px; */
  color: white;
  margin: 33%;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.viewsCount {
  color: white;
  font-size: 20px;
  position: inherit;
}

.navbar {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}
.hh {
  margin: 6px;
  /*border: 0.5px solid #c0c0c0;*/
  border-radius: 3px;
}
.buy-btn {
  width: 90%;
  background-color: black !important;
  border-radius: 4px;
  color: white;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  border: none;
  margin-top: 6px;
}
.buy-btn:active {
  width: 90%;
  background-color: black !important;
  border-radius: 4px;
  color: white;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  border: none;
  margin-top: 6px;
}
.vodal,
.vodal-mask {
  z-index: 200 !important;
}
.mymodal >>> .vodal-dialog {
  padding: 0 !important;
}
.center-it {
  display: flex;
  justify-content: center;
  margin-left: -15px;
}
.sm-icons {
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
}
.disabledbutton {
  pointer-events: none;
}
button:hover {
  border: none !important;
  outline: none;
}

button:focus {
  border: none !important;
  outline: none;
}
.swal2-popup {
  max-width: 280px !important;
}
.activeS {
  color: black !important;
  z-index: 99 !important;
}
.vodal-mask {
  z-index: 2100 !important;
}
.vodal {
  z-index: 2100 !important;
}
.circle span {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90px;
  color: black;
  font-weight: bold;
  font-size: 20px;
}
.circle {
  background: #ffffff;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  margin: 5px;
  position: relative;
}
.wtv-logo-container {
  height: 70px;
  width: 80px;
  top: 0px;
  position: absolute;
}
.brand-logo {
  width: 62px;
  top: 5px;
  position: absolute;
}

.footer-class {
  position: absolute;
  bottom: 0px;
  height: 7%;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fbtn {
  background-color: black;
  color: white;
  width: 100%;
  height: 28px;
  border: 1px solid #000000;
  box-sizing: border-box;
  backdrop-filter: blur(4px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 13px;
  font-size: 14px;
  line-height: 16px;
  font-family: Roboto;
}

.mousehvr {
  position: relative;
  text-align: center;
}
.t-class {
  position: absolute;
  bottom: 6%;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(4px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 18px;
  display: flex;
  justify-content: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 11px !important;
  /* identical to box height */
  min-height: 18px;
  color: #ffffff;
  width: 80%;
  margin-left: 10%;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 5px;
  padding-right: 5px;
}

button[disabled] {
  opacity: 0.2;
}
/* .isMobileView .chatContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 245px;
  width: 100%;
  overflow-y: scroll;
  background: transparent;
} */
#mainContainer {
  display: table-caption;
}
/* .isMobileView .inputContainer {
  position: absolute;
  bottom: 0;
  right: 0;
} */

.chatBtn {
  position: absolute;
  top: 27%;
  right: 7px;
  border-radius: 12px;
}
.shareContainer {
  width: 9%;
  height: 6%;
  color: white;
  background-color: white;
  border-radius: 12px;
  position: absolute;
  top: 55%;
  right: 12px;
  cursor: pointer;
}
.searchContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.searchTitle {
  color: white;
  font-size: 14px;
}
.inputSearchContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.inputSearchContainer input {
  width: 90%;
  height: 40px;
  border-radius: 5px;
  padding: 0px 10px;
  outline: none;
}
.submitBtn {
  margin-top: 15px;
  border: 2px solid black;
  border-radius: 5px;
  padding: 2px 10px;
  color: white;
  background-color: #000000;
}
.submitBtn:hover,
.submitBtn:active {
  border: 2px solid black !important;
  border-radius: 5px !important;
  padding: 2px 10px !important;
}
.screenShareContainer {
  position: absolute;
  top: 54%;
  right: 9px;
  cursor: pointer;
  z-index: 5;
}
.redirect {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 10;
  background-color: blue;
  color: white;
  font-size: 13px;
  font-weight: bold;
  border-radius: 10px;
  padding: 2px 6px;
}

@-moz-document url-prefix() {
  #scrolldiv {
    overflow-y: auto !important;
  }
}

/** Chat in web view */
.chat-wrapper.isWebView {
  background: white;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 390px;
}
.chat-wrapper.isWebView .chat-header {
  font-size: 24px;
  font-weight: 700;
  margin: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
}
.chat-wrapper.isWebView .chat-header .chat-close {
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
}
.isWebView .chat-content-wrapper {
  height: calc(100vh - 140px);
  overflow: scroll;
  padding: 8px 12px;
}
.isWebView .chat-block {
  background: #eee;
  border-radius: 8px;
  display: flex;
  margin-bottom: 8px;
  padding: 8px;
}
.isWebView .chat-avatar {
  height: 32px;
  width: 32px;
  margin-right: 8px;
}
.isWebView .chat-avatar img {
  border-radius: 50%;
  display: inline-block;
  object-fit: cover;
  width: 32px;
}
.isWebView .chat-message-block {
}
.isWebView .chat-message-block .chat-name {
  font-weight: 700;
}
.isWebView .chat-message-block .chat-message {
  line-height: 1.2;
}
.isWebView .chat-footer {
  border-top: 1px solid #ddd;
  padding: 12px;
  position: relative;
  height: 84px;
}
.isWebView .chat-footer .chat-message-input {
  background: transparent;
  border: 0;
  outline: none;
  padding-right: 64px;
  width: 100%;
}
.isWebView .chat-footer .chat-send-button {
  position: absolute;
  right: 12px;
  min-width: 60px;
  border: 0;
  background: black;
  border-radius: 4px;
  height: 32px;
  color: white;
  font-weight: 700;
  top: 24px;
}

/** Chat in mobile view */
.chat-wrapper.isMobileView {
  display: flex;
  flex-direction: column;
  text-align: left;
  height: 50vh;
  width: 100vw;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 99;
}
.chat-wrapper.isMobileView .chat-header {
  display: none;
}
.isMobileView .chat-content-wrapper {
  flex: 1;
  font-size: 12px;
  overflow: scroll;
  min-width: 250px;
  padding: 0 8px;
  text-align: left;
  width: 70%;
}
.isMobileView .chat-block {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  display: flex;
  margin-bottom: 8px;
  padding: 8px;
}
.isMobileView .chat-avatar {
  height: 32px;
  width: 32px;
  margin-right: 8px;
}
.isMobileView .chat-avatar img {
  border-radius: 50%;
  display: inline-block;
  object-fit: cover;
  width: 32px;
}
.isMobileView .chat-message-block {
}
.isMobileView .chat-message-block .chat-name {
  font-weight: 700;
}
.isMobileView .chat-message-block .chat-message {
  line-height: 1.2;
}
.isMobileView .chat-footer {
  border-top: 1px solid #ddd;
  padding: 8px;
  position: relative;
  height: 70px;
}
.isMobileView .chat-footer .chat-message-input {
  border: 1px solid #000;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.2);
  color: white;
  outline: none;
  padding: 8px 72px 8px 8px;
  height: 100%;
  width: 100%;
}
.isMobileView .chat-footer .chat-send-button {
  position: absolute;
  right: 20px;
  min-width: 60px;
  border: 0;
  background: black;
  border-radius: 4px;
  height: 32px;
  color: white;
  font-weight: 700;
  top: 20px;
}
.live-shop-container {
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 35%;
}

.product-layout-wrapper {
  background: white;
  width: 390px;
}
.buy-now-redirect {
  display: flex;
  background: black;
  width: 100px;
  align-items: center;
  justify-content: center;
  margin: 8px auto 0;
  color: white;
  font-size: 14px;
  line-height: 1;
  padding: 8px;
  border-radius: 4px;
}
</style>
